import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PhoneIcon } from '../assets/icons/contact-phone.svg';
import { ReactComponent as EmailIcon } from '../assets/icons/email.svg';
import { ReactComponent as FacebookIcon } from '../assets/icons/facebook.svg';
import { ReactComponent as LinkedInIcon } from '../assets/icons/linkedin.svg';
import { ReactComponent as YouTubeIcon } from '../assets/icons/youtube.svg';
import { ReactComponent as InstagramIcon } from '../assets/icons/instagram.svg';
import AppMap from './app-map';
import './app-footer.scss';


const AppFooter = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();




    const categories: [] = t("footer.categories", { returnObjects: true }) as [];

    const handleNavigate = (path: string) => {
        navigate(path);
        setTimeout(() => {
            const element = document.querySelector('.app-header') || document.querySelector('.app-mobile-header');
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }, 100);
    }



    return (
        <div className="app-footer">
            <div className='placeh'></div>
            <div className='categories-contact-info-container-section'>
                <div className='categories-contact-info-container'>
                    <div className='flex-row'>
                        <div className='categories'>
                            {categories.map((category, index) => (
                                <div key={index} className="category">
                                    {category}
                                    {index < categories.length - 1 && <div className="dot"></div>}
                                </div>
                            ))}
                        </div>
                        <div className='contact-info'>
                            <div
                                className='phone'
                                style={{ cursor: 'pointer' }}
                                onClick={() => { window.location.href = 'tel:+40212330613' }}
                            >
                                <div className='phone-icon'>
                                    <PhoneIcon />
                                </div>
                                +40 212 330 613
                            </div>
                            <div
                                className='email'
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    window.location.href = `mailto:secretariat@scdpbaneasa.ro`;
                                }}>
                                <div className='email-icon'>
                                    <EmailIcon />
                                </div>
                                {t('footer.contact.email')}
                            </div>
                        </div>
                    </div>
                    <div className="social-icons">
                        <a href="https://www.facebook.com/Cercetare.SCDP.Baneasa/" target="_blank" rel="noopener noreferrer">
                            <FacebookIcon />
                        </a>
                        <a href="https://ro.linkedin.com/company/scdpbaneasa" target="_blank" rel="noopener noreferrer">
                            <LinkedInIcon />
                        </a>
                        <a href="https://www.youtube.com/@scdpbaneasa9419" target="_blank" rel="noopener noreferrer">
                            <YouTubeIcon />
                        </a>
                        <a href="https://www.instagram.com/scdp.baneasa?igsh=ZjduZ25mcGp6Y2Rv&utm_source=qr" target="_blank" rel="noopener noreferrer">
                            <InstagramIcon />
                        </a>
                    </div>
                </div>


            </div>
            <div className='details-container'>
                <div className='content'>
                    <div className="description-links-container">
                        <div className="footer-columns">
                            <div className="column">
                                <h4>{t('footer.links.title')}</h4>
                                <ul>
                                    <li style={{ cursor: 'pointer' }} onClick={() => handleNavigate('about-us')}>{t('footer.links.about-us')}</li>
                                    <li style={{ cursor: 'pointer' }} onClick={() => handleNavigate('services')}>{t('footer.links.services')}</li>
                                    <li style={{ cursor: 'pointer' }} onClick={() => handleNavigate('contact')}>{t('footer.links.contact')}</li>
                                </ul>
                            </div>
                            <div className="column">
                                <h4>{t('footer.schedule.title')}</h4>
                                <ul>
                                    <li>{t('footer.schedule.monday-thursday')}</li>
                                    <li>{t('footer.schedule.friday')}</li>
                                    <li>{t('footer.schedule.saturday')}</li>
                                    <li>{t('footer.schedule.sunday')}</li>
                                </ul>
                            </div>
                            <div className="column address">
                                <h4>{t('footer.address.title')}</h4>
                                <p>{t('footer.address.location')}</p>
                                <AppMap latitude={44.498598087127945} longitude={26.075279085181872} />
                            </div>
                        </div>
                    </div>
                    <div className='separator'></div>
                    <div className="footer-bottom">
                        {/* <p>{t('footer.footer-bottom.terms')} | {t('footer.footer-bottom.privacy-policy')}</p> */}
                        <p>{t('footer.footer-bottom.copyright')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppFooter;
