import { useTranslation } from "react-i18next";
import SideMenu from "../../components/side-menu";
import './contact-page.scss';
import ContactForm from "../../components/contact-form";

const ContactPage = () => {
    const { t } = useTranslation();

    return (
        <div className="contact-page page">
            <SideMenu />
            <div className="page-content">
                <div className="sections">
                    <div className="topic-container">
                        <div className="title">{t('contact.page.section-1.title')}</div>
                        <div className="subtitle-content">
                            <p className="normal-text">{t('contact.page.section-1.item-1')}</p>
                            <p
                                className="normal-text download"
                                style={{ cursor: 'pointer' }}
                                onClick={() => { window.location.href = 'tel:+40212330613' }}
                            >{t('contact.page.section-1.item-2')}</p>
                            {/* <p className="normal-text">{t('contact.page.section-1.item-3')}</p> */}
                            {/* <p className="normal-text">{t('contact.page.section-1.item-4')}</p> */}
                            <p className="normal-text">{t('contact.page.section-1.item-5')}</p>
                        </div>
                    </div>
                    <div className="topic-container">
                        <div className="title">{t('contact.page.section-2.title')}</div>
                        <div className="subtitle-content">
                            <p className="subtitle">{t('contact.page.section-2.item-1')}</p>
                            <p className="normal-text">{t('contact.page.section-2.item-2')}</p>
                            <p className="normal-text">{t('contact.page.section-2.item-3')}</p>
                            <p className="subtitle">{t('contact.page.section-2.item-4')}</p>
                            <p className="normal-text">{t('contact.page.section-2.item-5')}</p>
                        </div>
                    </div>
                </div>
                <ContactForm />
            </div>
        </div>
    )
}

export default ContactPage;