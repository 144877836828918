import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import roFlag from '../assets/icons/ro-flag.svg';
import enFlag from '../assets/icons/en-flag.svg';
import './switch-language.scss';

const languageOptions = [
    { label: 'Română', value: 'ro', flag: roFlag },
    { label: 'English', value: 'en', flag: enFlag },
];

const SwitchLanguage = () => {
    const { i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(
        'ro'
    );

    const handleLanguageChange = (language: string) => {
        i18n.changeLanguage(language);
        localStorage.setItem('selectedLanguage', language);
        setSelectedLanguage(language);
        setIsOpen(false); 
    };

    return (
        <div className="switch-language">
            <div className="selected-language" onClick={() => setIsOpen(!isOpen)}>
                <img
                    src={languageOptions.find(lang => lang.value === selectedLanguage)?.flag}
                    alt={selectedLanguage}
                    className="language-flag"
                />
                <span>
                    {/* {languageOptions.find(lang => lang.value === selectedLanguage)?.label} */}
                </span>
            </div>
            {isOpen && (
                <ul className="language-dropdown">
                    {languageOptions.map(lang => (
                        <li key={lang.value} onClick={() => handleLanguageChange(lang.value)}>
                            <img src={lang.flag} alt={lang.label} className="language-flag" />
                            {/* {lang.label} */}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SwitchLanguage;
