import React, { useEffect, useState } from 'react';
import { ReactComponent as LeafIcon } from '../assets/icons/leaf.svg';
import { ReactComponent as LeftIcon } from '../assets/icons/left.svg';
import './carousel-fade.scss';



const ITEMS_PER_PAGE = 3;

interface CarouselFadeProps {
    theme: 'dark' | 'light'
    helperText: string;
    title: string;
    items: React.ReactNode[]; 
}

const CarouselFade:React.FC<CarouselFadeProps> = ({theme, helperText, title, items}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [fade, setFade] = useState(true);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


    const visibleItems = isMobile ? items : items.slice(currentIndex, currentIndex + ITEMS_PER_PAGE);

    const handlePrev = () => {
        setFade(false);
        setTimeout(() => {
            setCurrentIndex((prevIndex) => Math.max(0, prevIndex - ITEMS_PER_PAGE));
            setFade(true);
        }, 300);
    };

    const handleNext = () => {
        setFade(false);
        setTimeout(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex + ITEMS_PER_PAGE >= items.length ? 0 : prevIndex + ITEMS_PER_PAGE
            );
            setFade(true);
        }, 300);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);


    

    return (
        <div className='carousel-wrapper'>
            <div className='carousel-container'>
                <div>
                    <div>
                <div className="title-help">
                    <LeafIcon width={50} height={50} />
                    <span>{helperText}</span>
                </div>
                </div>
                </div>
                <div className='title-buttons'>
                    <span className={`title ${theme}`}>{title}</span>
                    <div className='buttons-container hide-md'>
                        <div className={`button ${theme}`} onClick={handlePrev}>
                            <LeftIcon />
                        </div>
                        <div className={`button ${theme}`} onClick={handleNext}>
                            <LeftIcon className='right' />
                        </div>
                    </div>
                </div>
                    <div className={`items ${fade ? 'fade-in' : 'fade-out'}`}>
                        {visibleItems.map((item, index) => (
                            <div className="carousel-item-wrapper" key={index}>
                            {item}
                        </div>
                        ))}
                    </div>
            </div>
            </div>
    );
};

export default CarouselFade;
