import { useImageModal } from '../../../context/image.modal.context';
import './laboratory-3-page.part.gallery.scss';

const Laboratory3PagePartGallery: React.FC = () => {

    const { showImage } = useImageModal();
    
    return (
    <>
    <div className='gallery-container'>
    {Array.from({length: 15}).map((_,index) => {
        const image = require(`../../../assets/images/laboratory-2/laboratory-2-gallery-${index+1}.png`)
       return <img key={index} src={image} onClick={() => showImage(image)}/>
    })}
    </div>
    </>
    )
}

export default Laboratory3PagePartGallery;