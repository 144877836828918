import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from '../assets/icons/arrow.svg';
import './header-mobile-info-section.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import bgHome from '../assets/images/home-bg.png';
import bgAboutUs from '../assets/images/about-us-bg.png';
import bgResearch from '../assets/images/research-bg.png';
import bgDevelopment from '../assets/images/development-bg.png';
import bgEvents from '../assets/images/events-bg.png';
import bgServices from '../assets/images/services-bg.png';
import bgAnnouncements from '../assets/images/announcements-bg.png';
import bgContact from '../assets/images/contact-bg.png';

import bgNationalProject1 from '../assets/images/national-projects-1/1-bg.png';
import bgNationalProject2 from '../assets/images/national-projects-1/2-bg.png';

import bgLaboratory1 from '../assets/images/laboratory-1-bg.png';
import bgLaboratory2 from '../assets/images/laboratory-2-bg.png';

import bgBase1 from '../assets/images/base-1-bg.png';
import bgBase2 from '../assets/images/base-2-bg.png';

import bgWorkshop1 from '../assets/images/workshop-1/1-bg.png';
import bgWorkshop2 from '../assets/images/workshop-2/2-bg.png';
import bgWorkshop3 from '../assets/images/workshop-3/3-bg.png';
import bgWorkshop4 from '../assets/images/workshop-4/4-bg.png';

import bgEvent1 from '../assets/images/event-1-bg.png';
import bgEvent2 from '../assets/images/event-2-bg.png';
import bgEvent3 from '../assets/images/event-3-bg.png';
import bgEvent4 from '../assets/images/event-4-bg.png';

const routeBackgrounds: Record<string, string> = {
    'home': bgHome,
    'about-us': bgAboutUs,
    'research': bgResearch,
    'development': bgDevelopment,
    'events': bgEvents,
    'services': bgServices,
    'announcements': bgAnnouncements,
    'contact': bgContact,

    'laboratory-1': bgLaboratory1,
    'laboratory-2': bgLaboratory2,

    'base-1': bgBase1,
    'base-2': bgBase2,

    'project-1': bgNationalProject1,
    'project-2': bgNationalProject2,

    'workshop-1': bgWorkshop1,
    'workshop-2': bgWorkshop2,
    'workshop-3': bgWorkshop3,
    'workshop-4': bgWorkshop4,

    'event-1': bgEvent1,
    'event-2': bgEvent2,
    'event-3': bgEvent3,
    'event-4': bgEvent4,
};

const HeaderMobileInfoSection = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const [backgroundImage, setBackgroundImage] = useState('');
    const [bgLoaded, setBgLoaded] = useState(false);


    const pathSegments = location.pathname.split('/').filter(Boolean);

    let translationKey = 'home';  

    if (pathSegments[0] === 'research' && pathSegments[1]?.startsWith('national-projects')) {
        const projectGroup = pathSegments[1].split('-')[2];  
        const projectId = pathSegments[2] || '1';            

        translationKey = `national-projects-${projectGroup}.${projectId}`;
    } else if (pathSegments[0] === 'events' && pathSegments[1]?.startsWith('workshop')) {
        const projectGroup = pathSegments[1].split('-')[2];  
        const projectId = pathSegments[2] || '1';     
               

        translationKey = `workshop-${projectId}`;
    } else {
        translationKey = pathSegments[pathSegments.length - 1] || 'home';
    }

    const isHome = translationKey === 'home';

    const isMadr = ['national-projects-1.1', 'national-projects-1.2', 'national-projects-1.3'].includes(translationKey);
    const isPNDR = ['national-projects-2.1', 'national-projects-2.2', 'national-projects-2.3'].includes(translationKey);


    
    useEffect(() => {
        const pathSegments = location.pathname.split('/').filter(Boolean);
        let key = '';

        if (pathSegments[1]?.startsWith('national-projects')) {
            key = pathSegments[1] === 'national-projects-1' ? 'project-1' : 'project-2';
        } else if (pathSegments[1] === 'workshop') {
            key = `workshop-${pathSegments[2]}`;
        } else if (pathSegments[1]) {
            key = pathSegments[1];
        } else {
            key = pathSegments[0] || 'home';
        }

        const matchedImage = routeBackgrounds[key] || bgHome;
        setBgLoaded(false);

        const img = new Image();
        img.src = matchedImage;
        img.onload = () => {
            setBackgroundImage(matchedImage);
            setBgLoaded(true);
        };
    }, [location.pathname]);

    return (
        <div className='mobile-info-section-container'>
                <div className='image-container' style={{ background: `url(${backgroundImage})` }}>
                </div>
            <div className="section">
                <div className='title-help-text'>{t(`header.${translationKey}.badge-text`)}</div>
                <div className='title'>
                    {t(`header.${translationKey}.title`)}
                </div>
                <div className='separator'></div>
                {(isMadr || isPNDR) && <div className='subtitle'>{t(`header.${translationKey}.description`)}</div>}
                {/* {isHome &&
                    <div className='details-container' style={{cursor: 'pointer'}} onClick={() => navigate('about-us')}>
                        <span>Detalii</span>
                        <ArrowIcon />
                    </div>
                } */}
            </div>
        </div>
    )
}

export default HeaderMobileInfoSection;