import SideMenu from "../../components/side-menu";
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import './events-page.scss';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LogoIcon } from '../../assets/icons/logo-baneasa.svg';
import { ReactElement, ReactNode } from "react";

const eventImage1 = require('../../assets/images/event-1.png');
const eventImage2 = require('../../assets/images/event-2.png');
const eventImage3 = require('../../assets/images/event-3.png');

interface Item {
    id:number;
    image?: string;
    title: string;
    icon?: ReactNode
}

const EventsPage = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const items: Item[] = [
        {   
            id: 4,
            icon: <LogoIcon/>,
            title: t('events.page.events-list.event-4.name'),
        },
        {
            id: 3,
            image: eventImage3,
            title: t('events.page.events-list.event-3.name'),
        },
        {
            id: 2,
            image: eventImage2,
            title: t('events.page.events-list.event-2.name'),
        },
        {
            id: 1,
            image: eventImage1,
            title: t('events.page.events-list.event-1.name'),
        },
     
        
        
    ]
    const handleNavigate = (path: any) => {
        navigate(`event-${path}`)
        setTimeout(() => {
            const element = document.querySelector('.app-header') || document.querySelector('.app-mobile-header');
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            } 
        }, 100);
    }

    const eventsItems = items.map((item, index) => (
        <div className='item' key={index} onClick={() => handleNavigate(item.id)}>
            <div className='image-container'>
                { item.image ?
                <img src={item.image} alt={item.title} /> : <div className='no-image'>{item.icon}</div>
                }
                
                {/* <div className='category'><span>{item.category}</span></div> */}
                <div className='arrow-container'>
                    <ArrowIcon />
                </div>
            </div>
            <div className='title'>{item.title}</div>

        </div>
    ));
    return (
        <div className="events-page page">
            <div className="page-content">
                <div className="events-container">
                    {eventsItems.map((item) => (item))}
                </div>
            </div>
        </div>
    )
}

export default EventsPage;