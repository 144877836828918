import { ReactComponent as LeafIcon } from '../../assets/icons/leaf.svg';
import { useTranslation } from 'react-i18next';
import InfoContactCard from '../../components/info-contact-card';
import DownloadButton from '../../components/download-button';
import SideMenuSubBases from './bases.side-menu.subbases';
import './bases.side-menu.scss';



const SideMenuBases = () => {
    const { t,i18n } = useTranslation();
    return (
        <div className='side-menu-container-bases'>
            <SideMenuSubBases />
            <InfoContactCard />
            <div className='about-baneasa-container'>
                <DownloadButton
                    theme='white'
                    label={t('side-menu.about-baneasa.title')}
                    fileId={i18n.language === 'ro'
                        ? '34fedff9-8892-4940-99c3-66d22dc1195a'
                        : 'd3ba37f7-d16a-48bd-81fe-7c7d50ef9bd2'}
                    fileName={i18n.language === 'ro'
                        ? 'Broșură SCDPB RO'
                        : 'Broșură SCDP B-ENG'
                    }
                    useLeafIcon
                />
                {/* <DownloadButton theme='yellow' label={t('side-menu.button.catalog')}/> */}
            </div>
        </div>
    )
}
export default SideMenuBases;