import { useNavigate } from 'react-router-dom';
import './home-page.section.news.scss';
import { useTranslation } from 'react-i18next';

const firstNewsImage = require('../../assets/images/new-first-image.png');
const secondtNewsImage = require('../../assets/images/new-second-image.png');

const HomePageSectionNews = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNavigate = (path: string) => {
        navigate(path);
        setTimeout(() => {
            const element = document.querySelector('.app-header') || document.querySelector('.app-mobile-header');
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            } 
        }, 100);
    }

    return (
        <div className="home-page-news-container">
            <div className='title'>
                {t('home-page.news.title')}
            </div>
            {/* <div className='help'>{t('home-page.news.help')}</div> */}
            <div className='item'>
                <img
                    src={firstNewsImage}
                    alt=""
                    style={{ cursor: 'pointer' }}
                    onClick={() => { handleNavigate('/events/event-3') }}
                />
                <div className='content'>
                    <div className='navigate' onClick={() => { handleNavigate('/events/event-3') }}>{t('home-page.news.item-1.title')}</div>
                </div>
            </div>
            <div className='separator'></div>
            <div className='item'>
                {/* <div className='no-image'></div> */}
                <img
                    src={secondtNewsImage}
                    alt=""
                    style={{ cursor: 'pointer' }}
                    onClick={() => { handleNavigate('/events/event-4') }}
                />
                    <div className='content'>
                        <div className='navigate' onClick={() => { handleNavigate('/events/event-3') }}>{t('home-page.news.item-2.title')}</div>
                    </div>
            </div>
        </div>
    )
}

export default HomePageSectionNews;
