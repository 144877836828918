import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import CarouselFade from '../../components/carousel-fade';
import './home-page.section.research.scss';

const researchBg = require('../../assets/images/research-bg-home.png');
const researchItem1 = require('../../assets/images/proiect-national-1.png');
const researchItem2 = require('../../assets/images/proiect-national-2.png');

interface Item {
    image: string;
    category?: string;
    title: string;
    description: string;
}

const HomePageSectionResearch = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const items: Item[] = [
        {
            image: researchItem1,
            category: t('home-page.research.category.research'),
            title: t('home-page.research.item-1.title'),
            description: t('home-page.research.item-1.description')
        },
        {
            image: researchItem2,
            category: t('home-page.research.category.research'),
            title: t('home-page.research.item-2.title'),
            description: t('home-page.research.item-2.description')
        }
    ];

    const handleNavigate = (path: string) => {
        navigate(path);
        setTimeout(() => {
            const element = document.querySelector('.app-header') || document.querySelector('.app-mobile-header');
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            } 
        }, 100);
    }

    const carouselItems = items.map((item, index) => (
        <div className='item' key={index}>
            <div className='image-container'>
                <img src={item.image} alt={item.category} />
            </div>
            <div className='category'>{item.category}</div>
            <div className='title'>{item.title}</div>
            <div className='separator'></div>
            <span className='description'>{item.description}</span>
            <div className='arrow-container'>
                <ArrowIcon style={{cursor: 'pointer'}} onClick={() => {
                        handleNavigate(`/research/national-projects-${index+1}/1`);
                }}/>        
            </div>
        </div>
    ));
    
    return (
        <div className="homepage-research">
            <div className='bg-image-container'>
                <img src={researchBg} alt="Background" />
            </div>
            <CarouselFade
                theme='dark'
                helperText={t('home-page.research.helper-text')}
                title={t('home-page.research.title')}
                items={carouselItems}
            />
        </div>
    );
};

export default HomePageSectionResearch;
