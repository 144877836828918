import { useImageModal } from '../../../context/image.modal.context';
import './laboratory-1-page.part.gallery.scss';

const Laboratory1PagePartGallery: React.FC = () => {

    const { showImage } = useImageModal();
    
    return (
    <>
    <div className='gallery-container'>
    {Array.from({length: 15}).map((_,index) => {
        const image = require(`../../../assets/images/laboratory-1/laboratory-1-gallery-${index+1}.png`)
       return <img key={index} src={image} onClick={() => showImage(image)}/>
    })}
    </div>
    </>
    )
}

export default Laboratory1PagePartGallery;