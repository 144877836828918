import { useTranslation } from "react-i18next";
import SideMenu from "../../../components/side-menu";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CheckIcon } from '../../../assets/icons/verified-yellow.svg';
import './event-3-page.scss';


const eventMainImage = require('../../../assets/images/event-3/event-3-main.png');

const Event3Page = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNavigate = (index: number) => {
        navigate(`/events/workshop/${index}`)
    }

    return (
        <div className="event-3-page page">
            <SideMenu showPublicInterest={false} />
            <div className="page-content">
                <img src={eventMainImage} />
                <div className="topic-container">
                    <div className="title">{t('events.page.event-3.title')}</div>
                    <div className="section-content">
                        <div className="normal-text">{t('events.page.event-3.description')}</div>
                    </div>
                    <div className="subtitle-content">
                        <div className="subtitle ">{t('events.page.event-3.program.list.title')}</div>
                        <div className="normal-text">{t('events.page.event-3.program.list.description')}</div>
                        <ul>
                            {Array.from({ length: 6 }).map((_, index) => (
                                <li><CheckIcon /><p>{t(`events.page.event-3.program.list.item-${index + 1}`)}</p></li>
                            ))}
                        </ul>

                        {Array.from({length: 4}).map((_,idx) => {
                            return (
                                <div className="normal-text">{t(`events.page.event-3.text-${idx+1}`)}</div>
                            )
                        })}
                    </div>


                </div>
            </div>
        </div>
    )
}

export default Event3Page;