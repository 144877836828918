import { useTranslation } from "react-i18next";
import SideMenu from "../../../components/side-menu";
import './base-2-page.scss';
import AppMap from "../../../components/app-map";
import SideMenuBases from "../bases.side-menu";
import { useEffect, useState } from "react";
import { useImageModal } from "../../../context/image.modal.context";

const person1Image = require('../../../assets/images/base-2/person-1.png');
const person2Image = require('../../../assets/images/base-2/person-2.png');

const gallery1 = require('../../../assets/images/base-2/gallery-1.png');
const gallery2 = require('../../../assets/images/base-2/gallery-2.png');
const gallery3 = require('../../../assets/images/base-2/gallery-3.png');

interface ImageModel {
    imgSrc: string;
}

const Base2Page = () => {

    const { showImage } = useImageModal();

    const { t } = useTranslation();

    const [galleryImages, setGalleryImages] = useState<ImageModel[]>([]);

    useEffect(() => {
        loadGalleryImages();
    }, []);

    const loadGalleryImages = () => {
        try {
            const loadedImages: ImageModel[] = [];
            for (let i = 1; i <= 17; i++) {
                try {
                    const imgSrc = require(`../../../assets/images/base-2/gallery-${i}.png`);
                    loadedImages.push({ imgSrc });
                } catch (error) {
                    console.warn(`Image gallery-${i}.png not found in base-2`);
                }
            }
            setGalleryImages(loadedImages);
        } catch (error) {
            console.error("Error loading gallery images", error);
        }
    };


    return (
        <div className="base-1-page page">
            <SideMenuBases />
            <div className='page-content'>
                <div className='topic-container'>
                    <div className="title">{t('development.page.topic-2.base-2.title')}</div>
                    <div className="subtitle-content">
                        <div className="subtitle">{t('base-1.page.topic-1.item-1')}</div>
                        <div className="persons-container ">
                            <div className="person-container">
                                <img width={150} src={person1Image} />
                                <div className="person-info subtitle">
                                    <span>{t('base-2.page.personal.1.role')}
                                    </span>
                                    <span>{t('base-2.page.personal.1.name')}</span>
                                </div>
                            </div>
                            <div className="person-container">
                                <img width={150} src={person2Image} />
                                <div className="person-info subtitle">
                                    <span>{t('base-2.page.personal.2.role')}
                                    </span>
                                    <span>{t('base-2.page.personal.2.name')}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="separator"></div>
                    <div className="content-section">
                        <p className="subtitle">{t('base-2.page.topic-1.item-1')}</p>
                        <p className="normal-text">{t('base-2.page.topic-1.item-2')}</p>
                        <p className="normal-text">{t('base-2.page.topic-1.item-3')}</p>
                    </div>
                    <div className="subtitle-content">
                        <p className="subtitle">{t('base-2.page.topic-2.item-1')}</p>
                        <ul>
                            <li><p>{t('base-2.page.topic-2.item-2')}</p></li>
                            <li><p>{t('base-2.page.topic-2.item-3')}</p></li>
                            <li><p>{t('base-2.page.topic-2.item-4')}</p></li>
                            <li><p>{t('base-2.page.topic-2.item-5')}</p></li>
                            <li><p>{t('base-2.page.topic-2.item-6')}</p></li>
                        </ul>
                    </div>

                    <div className="subtitle-content">
                        <p className="subtitle">{t('base-2.page.topic-3.item-1')}</p>
                        <ul>
                            <li><p>{t('base-2.page.topic-3.item-2')}</p></li>
                            <li><p>{t('base-2.page.topic-3.item-3')}</p></li>
                            <li><p>{t('base-2.page.topic-3.item-4')}</p></li>
                            <li><p>{t('base-2.page.topic-3.item-5')}</p></li>
                            <li><p>{t('base-2.page.topic-3.item-6')}</p></li>
                            <li><p>{t('base-2.page.topic-3.item-7')}</p></li>
                            <li><p>{t('base-2.page.topic-3.item-8')}</p></li>
                        </ul>
                    </div>

                    <div className="subtitle-content">
                        <p className="subtitle">{t('base-2.page.topic-4.item-1')}</p>
                        <ul>
                            <li><p>{t('base-2.page.topic-4.item-2')}</p></li>
                            <li><p>{t('base-2.page.topic-4.item-3')}</p></li>
                        </ul>
                    </div>
                    <div className="subtitle-content">
                        <p className="subtitle">{t('base-2.page.topic-5.item-1')}</p>

                        <p className="normal-text">{t('base-2.page.topic-5.item-2')}</p>
                        <p className="normal-text">{t('base-2.page.topic-5.item-3')}</p>
                        <p className="normal-text">{t('base-2.page.topic-5.item-4')}</p>
                        <p className="normal-text">{t('base-2.page.topic-5.item-5')}</p>

                        <p style={{ marginTop: '20px' }} className="subtitle">{t('base-2.page.topic-5.item-6')}</p>

                        <ul>
                            <li><p>{t('base-2.page.topic-5.item-7')}</p></li>
                            <li><p>{t('base-2.page.topic-5.item-8')}</p></li>
                            <li><p>{t('base-2.page.topic-5.item-9')}</p></li>
                            <li><p>{t('base-2.page.topic-5.item-10')}</p></li>
                            <li><p>{t('base-2.page.topic-5.item-11')}</p></li>
                            <li><p>{t('base-2.page.topic-5.item-12')}</p></li>
                            <li><p>{t('base-2.page.topic-5.item-13')}</p></li>
                        </ul>
                    </div>
                </div>
                <div className="subtitle">{t('base-2.page.topic-6.item-1')}</div>
                <div className="images-container">
                    {galleryImages.map((img, index) => (
                        <img key={index} src={img.imgSrc} alt={`gallery-${index + 1}`} onClick={() => showImage(img.imgSrc)} />
                    ))}
                </div>
                <AppMap latitude={44.503609512531604} longitude={26.24650892845586} />
            </div>
        </div>
    )
}

export default Base2Page;