import axios from "axios";


const axiosInstance = axios.create({
    baseURL: 'https://alfresco.realgrowsoft.com/alfresco/api/-default-/public/alfresco/versions/1/',
    timeout: 300000,
    headers: {
      'Accept': 'application/pdf',
      'Authorization': 'Basic YWRtaW46YWRtaW4='
    },
  });





  export {axiosInstance}