import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import './research-page.part.laboratory.scss';
import { useNavigate } from 'react-router-dom';

const ResearchPagePartLaboratories = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

    const laboratories = [
        {
            image: require('../../assets/images/research-lab-2.png'),
            title: t('research.page.topic-2.laboratory-2'),
            path: 'laboratory-1',
        },
        {
            image: require('../../assets/images/research-lab-1.png'),
            title: t('research.page.topic-2.laboratory-1'),
            path: 'laboratory-2',
        }
    ];


    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');
                    }
                });
            },
            { threshold: 0.8 }
        );

        itemRefs.current.forEach((item) => {
            if (item) observer.observe(item);
        });

        return () => {
            itemRefs.current.forEach((item) => {
                if (item) observer.unobserve(item);
            });
        };
    }, []);

    const handleNavigate = (path: string) => {
        navigate(path);
        setTimeout(() => {
            const element = document.querySelector('.app-header') || document.querySelector('.app-mobile-header');
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            } 
        }, 100); 
    }


    return (
        <div className='research-page-part-laboratories'>
            <div className='title'>{t('research.page.topic-2.title')}</div>
            <div className='laboratories-container'>
                {laboratories.map((item, index) => (
                    <div
                        className='item'
                        key={index}
                        onClick={() => handleNavigate(item.path)}
                        ref={(el) => (itemRefs.current[index] = el)}
                    >
                        <div className='image-container'>
                            <img src={item.image} alt={item.title} />
                        </div>
                        <div className='title'>{item.title}</div>
                        <div className='arrow-container'>
                            <ArrowIcon />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ResearchPagePartLaboratories;
