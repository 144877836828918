import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SideMenu from "../../../components/side-menu";
import "./workshop.layout.scss";
import { useEffect, useState } from "react";

const WorkshopLayout: React.FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const [ galleryImages, setGalleryImages ] = useState<string []>([]);
    const workshopKey = `workshop-${id}`;

    const content: any = t(workshopKey, { returnObjects: true });

    useEffect(() => {
        let images = [];
        try {
            for (let i = 1; i <= 21; i++) {
                const imgSrc =  require(`../../../assets/images/workshop-${id}/gallery-${i}.png`)
                images.push(imgSrc)
            }
         } catch (e) {
            console.log(e);
        }
        setGalleryImages(images)
    }, [])

    if (!content) {
        return <div>Workshop content not found</div>;
    }

    return (
        <div className="workshop-layout page">
            <SideMenu showPublicInterest={false} />
            <div className="page-content">
                <div className="topic-container">
                    {content.title && (
                        <div className='title'>
                            {content.title.text}
                        </div>
                    )}

                    {content.sections &&
                        content.sections.map((section: any, index: number) => {
                            if (section.type === "paragraph") {
                                return (
                                        <p className="normal-text">{section.text}</p>
                                );
                            }

                            if (section.type === "bulletPoints") {
                                return (
                                    <div key={index} className="subtitle-content">
                                        <ul>
                                            {section.items.map(
                                                (item: string, idx: number) => (
                                                    <li key={idx}>
                                                        <p>{item}</p>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                );
                            }
                        })}
                </div>
                {galleryImages.length > 0 && (
                        <div className="workshop-gallery-container">
                            {galleryImages.map((src, index) => (
                                <div key={index}>
                                <img
                                    
                                    src={src}
                                />
                                </div>
                            ))}
                        </div>
                    )}
            </div>
        </div>
    );
};

export default WorkshopLayout;
