import { useTranslation } from "react-i18next";
import { ReactComponent as PdfIcon } from '../../../assets/icons/pdf.svg';  
import './laboratory-1-page.part.achievements.scss';
import axiosService from "../../../services/axios.service";

const imageCais = require('../../../assets/images/laboratory-1/ameliorare-cais-1.png');
const imagePiersic = require('../../../assets/images/laboratory-1/ameliorare-piersic-1.png');

const Laboratory1PagePartAchievements: React.FC = () => {
    const { t } = useTranslation();

    const handleDownload = async (fileName: string, id: string) => {
            try {
                const response = await axiosService.getDocumentById(id);
        
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');
            } catch (e) {
                console.log(e);
            }
    };

    return (
        <div className="laboratory-1-achievements">
            <p className="normal-text">{t('laboratory-1.page.section.achievements.topic-1.item-1')}</p>

            <div className="achievements-container">
                <div className="achievement-section">
                    <h3 className="normal-text">{t('laboratory-1.page.section.achievements.topic-2.item-1')}</h3>
                    <img src={imageCais} alt="Ameliorare cais" className="achievement-image" />
                    <ul className="normal-text">
                        <li>{t('laboratory-1.page.section.achievements.topic-2.item-3')}</li>
                        <li>{t('laboratory-1.page.section.achievements.topic-2.item-4')}</li>
                        <li>{t('laboratory-1.page.section.achievements.topic-2.item-5')}</li>
                        <li>{t('laboratory-1.page.section.achievements.topic-2.item-6')}</li>
                        <li>{t('laboratory-1.page.section.achievements.topic-2.item-7')}</li>
                    </ul>
                </div>

                <div className="achievement-section">
                    <h3 className="normal-text">{t('laboratory-1.page.section.achievements.topic-3.item-1')}</h3>
                    <img src={imagePiersic} alt="Ameliorare piersic" className="achievement-image" />
                    <ul className="normal-text">
                        <li>{t('laboratory-1.page.section.achievements.topic-3.item-2')}</li>
                        <li>{t('laboratory-1.page.section.achievements.topic-3.item-3')}</li>
                        <li>{t('laboratory-1.page.section.achievements.topic-3.item-4')}</li>
                        <li>{t('laboratory-1.page.section.achievements.topic-3.item-5')}</li>
                        <li>{t('laboratory-1.page.section.achievements.topic-3.item-6')}</li>
                    </ul>
                </div>
            </div>

            <div className="normal-text additional-info">
                <p>{t('laboratory-1.page.section.achievements.topic-4.item-1')}</p>
                <p>{t('laboratory-1.page.section.achievements.topic-4.item-2')}</p>
                <p>{t('laboratory-1.page.section.achievements.topic-4.item-3')}</p>
                <p className="download" onClick={() => handleDownload('Soiuri-din-catalogul-ISTS-1', '7026558a-e9f0-4570-9482-1f12567e7f04')}>{t('laboratory-1.page.section.achievements.topic-4.item-4')} <PdfIcon/></p>
            </div>
        </div>
    );
}

export default Laboratory1PagePartAchievements;
