import { ImageModalProvider } from './context/image.modal.context';
import AppRouter from './router/app-router';

function App() {
  return (
    <ImageModalProvider>
      <AppRouter />
    </ImageModalProvider>
  );
}

export default App;
