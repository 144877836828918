import { ReactComponent as PdfIcon } from '../assets/icons/pdf-small.svg';
import { ReactComponent as DownloadIcon } from '../assets/icons/download-small.svg';
import './download-item.scss';
import axiosService from '../services/axios.service';

interface DownloadItemProps {
    text: string;
    fileId?: string;
    fileName?: string;
}

const DownloadItem: React.FC<DownloadItemProps> = ({ text, fileId, fileName }) => {

    const handleDownload = async (fileName: string, id: string) => {
            try {
                const response = await axiosService.getDocumentById(id);
        
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');
            } catch (e) {
                console.log(e);
            }
        };


    return (
        <div style={{ cursor: fileName && fileId ? 'pointer' : 'default' }}
            className='download-item'
            onClick={() => {
                if (fileName && fileId)
                    handleDownload(fileName, fileId)
            }}>
            <div>
                {fileName && fileId && <PdfIcon />}

            </div>
            <span>{text}</span>
            <div>
                {fileName && fileId && <DownloadIcon />}
            </div>
        </div>
    );
}

export default DownloadItem;