import { useTranslation } from 'react-i18next';
import DownloadButton from '../../../components/download-button';
import InfoContactCard from '../../../components/info-contact-card';
import SideMenuSubProjects from './national-projects.side-menu.subprojects';
import './national-projects.side-menu.scss';

const SideMenuProjects = () => {
    const { t, i18n } = useTranslation();
    return (
        <div className='side-menu-container-projects'>
            <SideMenuSubProjects />
            <InfoContactCard />
            <div className='about-baneasa-container'>
                
                <DownloadButton
                    theme='white'
                    label={t('side-menu.about-baneasa.title')}
                    fileId={i18n.language === 'ro'
                        ? '34fedff9-8892-4940-99c3-66d22dc1195a'
                        : 'd3ba37f7-d16a-48bd-81fe-7c7d50ef9bd2'}
                    fileName={i18n.language === 'ro'
                        ? 'Broșură SCDPB RO'
                        : 'Broșură SCDP B-ENG'
                    }
                    useLeafIcon
                />
            </div>
        </div>
    )
}
export default SideMenuProjects;