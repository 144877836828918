import { useTranslation } from "react-i18next";
import { ReactComponent as LeafIcon } from '../assets/icons/leaf.svg';
import { ReactComponent as DocumentIcon } from '../assets/icons/document.svg';
import './info-request-public-interest.scss';
import axiosService from "../services/axios.service";

interface InfoRequestPublicInterestProps {
    showJobApplication?: boolean
}

const InfoRequestPublicInterest: React.FC<InfoRequestPublicInterestProps> = ({ showJobApplication }) => {
    const { t } = useTranslation();

   const handleDownload = async (fileName: string, id: string) => {
            try {
                const response = await axiosService.getDocumentById(id);
        
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');
            } catch (e) {
                console.log(e);
            }
        };
        

    return (
        <div className="info-request-public-interest-container">
            {/* <div className="title-wrapper">
                <LeafIcon width={30} height={30} />
                <span className="title">{t('side-menu.public-interest.title')}</span>
            </div> */}
            <div className="content">
                <div className="item">
                    <span className="bolded">{t('side-menu.public-interest.subtitle')}</span>
                </div>
                {showJobApplication ?
                    <div className="item">
                        <div className="flex-row flex-align-center-center download"><DocumentIcon /> <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSddyvl22rt5Kv2lVxxPdkmZ2ZyKFhR5Bvm-XeAaHV-orJLaiQ/viewform">{t('side-menu.competition-form')}</a></div>
                    </div> :
                    <div className="item">
                        <div className="flex-row flex-align-center-center download" onClick={() => handleDownload(t('side-menu.service-supply-request'),'966a918e-435b-4939-8bbc-2c912850af33')}><DocumentIcon />{t('side-menu.service-supply-request')}</div>
                    </div>
                }

                <div className="item">
                    <span className="bolded">{t('side-menu.public-interest.office-hours.header')}</span>
                    <span>{t('side-menu.public-interest.office-hours.info')}</span>
                </div>
                {/* <div className="item">
                    <span className="bolded">{t('side-menu.public-interest.office-hours.header')}</span>
                    <span>{t('side-menu.public-interest.office-hours.info')}</span>
                </div> */}
                <div className="item">
                    <span className="bolded">{t('side-menu.public-interest.visiting-hours.header')}</span>
                    <span>{t('side-menu.public-interest.visiting-hours.info')}</span>
                </div>
                <div className="item">
                    <span className="bolded">{t('side-menu.public-interest.headquarters.header')}</span>
                    <span>{t('side-menu.public-interest.headquarters.info')}</span>
                </div>
                <div className="item row">
                    <span className="bolded">{t('side-menu.public-interest.phone.header')}</span>
                    <span className="download"  style={{cursor: 'pointer'}}
                        onClick={() => { window.location.href = 'tel:+40212330613' }}>{t('side-menu.public-interest.phone.info')}</span>
                </div>
                <div className="item row">
                    <span className="bolded">{t('side-menu.public-interest.email.header')}</span>
                    <span
                        className="download"
                        onClick={() => {
                            window.location.href = `mailto:secretariat@scdpbaneasa.ro`;
                        }}
                    >{t('side-menu.public-interest.email.info')}</span>
                </div>
                <div className="item row">
                    <span className="bolded">{t('side-menu.public-interest.site.header')}</span>
                    <span>{t('side-menu.public-interest.site.info')}</span>
                </div>
            </div>
        </div>
    )
}

export default InfoRequestPublicInterest;