import React from "react";
import { useTranslation } from "react-i18next";
import './laboratory-1-page.part.personal.scss';

const person1 = require("../../../assets/images/laboratory-1/personal-1.png");
const person2 = require("../../../assets/images/laboratory-1/personal-2.png");

const Laboratory1PagePartPersonal: React.FC = () => {
    const { t } = useTranslation();

    const teamMembers = [
        {
            image: person1,
            name: t("laboratory-1.page.section.person.1.name"),
            role: t("laboratory-1.page.section.person.1.info.1"),
            id: t("laboratory-1.page.section.person.1.info.2"),
            description: t("laboratory-1.page.section.person.1.description"),
        },
        {
            image: person2,
            name: t("laboratory-1.page.section.person.2.name"),
            role: t("laboratory-1.page.section.person.2.info.1"),
            id: t("laboratory-1.page.section.person.2.info.2"),
            description: t("laboratory-1.page.section.person.2.description"),
        },
    ];

    return (
        <div className="laboratory-1-personal">
            <div className="team-container">
                {teamMembers.map((member, index) => (
                    <div key={index} className="team-member">
                        <img src={member.image} alt={member.name} className="team-photo" />
                        <h3 className="team-name">{member.name}</h3>
                        <p className="team-role">{member.role}</p>
                        <p className="team-id">{member.id}</p>
                        <p className="team-description">{member.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Laboratory1PagePartPersonal;
