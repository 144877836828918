import './home-page.scss';
import HomePageSectionAboutus from './home-page.section.about-us';
import HomePageSectionLaboratories from './home-page.section.laboratories';
import HomePageSectionNews from './home-page.section.news';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import React from 'react';
import HomePageSectionResearch from './home-page.section.research';
import HomePageSectionEvents from './home-page.section.events';
import HomePageSectionBanner from './home-page.section.banner';
import { useTranslation } from 'react-i18next';

const HomePage = () => {
    const { t } = useTranslation();
    const textItems = Array.from({length: 4}).map((_, idx)=> t(`home-page.scrolling-text.item-${idx+1}`));

    return (
        <div className="home-page page">
            <HomePageSectionEvents/>
            <div className="scrolling-text">
                <span>
                    {textItems.map((item, index) => (
                        <React.Fragment key={index}>
                            {item}
                            <StarIcon className="star-icon" />
                        </React.Fragment>
                    ))}
                </span>
            </div>
            <HomePageSectionNews />
            {/* <HomePageSectionLaboratories /> */}
            {/* <HomePageSectionAboutus /> */}
           
            <HomePageSectionResearch/>
            <HomePageSectionBanner/>
        </div>
    );
}

export default HomePage;
