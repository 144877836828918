import { PropsWithChildren, useState } from "react";
import { ReactComponent as ArrowIcon } from '../assets/icons/dropdown-arrow.svg';
import './dropdown-section.scss';

interface DropdownSectionProps extends PropsWithChildren {
    title: string;
}

const DropdownSection: React.FC<DropdownSectionProps> = ({title,children}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSection = () => {
        setIsOpen((prev) => !prev);
    };
    return (
        <div className="statistics-section">
            <div className="section-header flex-align-space-between-center"  onClick={toggleSection}>
                <span className="dropdown-title">{title}</span>
                <ArrowIcon
                    className={`arrow-icon ${isOpen ? "open" : ""}`}
                   
                />
            </div>
           
            <div
                className={`dropdown-section-content ${isOpen ? "open" : ""}`}
            >
              {isOpen &&    children }
            </div>
        </div>
    )
}

export default DropdownSection;