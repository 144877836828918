import { useTranslation } from "react-i18next";
import SideMenu from "../../components/side-menu";
import DownloadButton from "../../components/download-button";
import './about-us-page.scss';
import axiosService from "../../services/axios.service";
import { ReactComponent as PdfIcon } from '../../assets/icons/pdf.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download-small.svg';

import { useEffect, useState } from "react";
import DropdownMenu from "../../components/dropdown-menu";
import DropdownSection from "../../components/dropdown-section";
import NavButton from "../../components/nav-button";


const aboutUsContent = require('../../assets/images/about-us-content.png');

const AboutUsPage: React.FC = () => {
    const { t } = useTranslation();

    const [programsActivityReport, setProgramActivityReport] = useState<{ name: string; id: string }[]>([]);
    const [regulations, setRegulations] = useState<{ name: string; id: string }[]>([]);
    const [assets2024, setAssets2024] = useState<{ name: string; id: string }[]>([]);
    const [assets2025, setAssets2025] = useState<{ name: string; id: string }[]>([]);
    const [gdpr, setGdpr] = useState<{ name: string; id: string }[]>([]);
    const [integrity, setIntegrity] = useState<{ name: string; id: string }[]>([]);
    const [paap, setPaap] = useState<{ name: string; id: string }[]>([]);
    const [procurementRecordsOver5000, setProcurementRecordsOver5000] = useState<{ name: string; id: string }[]>([]);
    const [procurementRecords, setProcurementRecords] = useState<{ name: string; id: string }[]>([]);
    const [anualProcurementRecords, setAnualProcurementRecords] = useState<{ name: string; id: string }[]>([]);
    const [activityReport, setActivityReport] = useState<{ name: string; id: string }[]>([]);
    const [budget, setBudget] = useState<{ name: string; id: string }[]>([]);
    const [balanceSheet, setBalanceSheet] = useState<{ name: string; id: string }[]>([]);
    const [trialBalance, setTrialBalance] = useState<{ name: string; id: string }[]>([]);
    const [budgetExecution, setBudgetExecution] = useState<{ name: string; id: string }[]>([]);
    const [staffingPlan, setStaffingPlan] = useState<{ name: string; id: string }[]>([]);
    const [positionsAndSalaries, setPositionsAndSalaries] = useState<{ name: string; id: string }[]>([]);
    const [ogranigrams, setOrganigrams] = useState<{ name: string; id: string }[]>([]);
    const [publicInterestDocuments, setPublciInterestDocuments] = useState<{ name: string, id: string }[]>([]);
    const [templateForms, setTemplateForms] = useState<{ name: string, id: string }[]>([]);
    const [contestDecision, setContestDecision] = useState<{ name: string, id: string }[]>([]);

    useEffect(() => {
        const fetchDocuments = async (nodeId: string) => {
            try {
                const response = await axiosService.getListOfDocumentsByNodeId(nodeId);
                const documentList = response.data.list.entries.map((entry: any) => ({
                    name: entry.entry.name.replace(/\.(pdf|docx)$/i, ""),
                    id: entry.entry.id
                }));
                return documentList;

            } catch (error) {
                console.error("Error fetching documents:", error);
            }
        };

        fetchDocuments("6dcfa762-20d6-4df6-99ec-2bf39c9984c4").then((documentList) => {
            if (documentList)
                setProgramActivityReport(documentList);
        })

        fetchDocuments("2816b3e5-7ab0-426b-9570-d5932c23e717").then((documentList) => {
            if (documentList)
                setRegulations(documentList)
        })

        fetchDocuments("0dc3c300-bccd-49b5-a20b-23aa5fe5923e").then((documentList) => {
            if (documentList)
                setAssets2024(documentList)
        });

        fetchDocuments("5d850523-84c5-4463-9cd0-baf4c5518c25").then((documentList) => {
            if (documentList)
                setAssets2025(documentList)
        });
 
       

        fetchDocuments("47de7854-18df-445a-a8c7-f78c8578705a").then((documentList) => {
            if (documentList)
                setGdpr(documentList)
        });

        fetchDocuments("ff5670bd-512a-408a-bd3f-a4368ee04c10").then((documentList) => {
            if (documentList)
                setIntegrity(documentList)
        });

        fetchDocuments("ec5b7e9f-9757-4554-9676-7965afc51e4e").then((documentList) => {
            if (documentList)
                setPaap(documentList)
        });

        fetchDocuments("355f13f1-c1b1-4833-967a-94247e5c7b9f").then((documentList) => {
            if (documentList)
                setProcurementRecordsOver5000(documentList)
        });

        fetchDocuments("d6d64739-e4b2-4f9c-9b28-56b4896187b8").then((documentList) => {
            if (documentList)
                setProcurementRecords(documentList)
        });

        fetchDocuments("04baefcc-4426-409c-b965-0d29e05707e1").then((documentList) => {
            if (documentList)
                setAnualProcurementRecords(documentList)
        });

        fetchDocuments("e8be8616-237c-4134-952b-364bed1efc28").then((documentList) => {
            if (documentList)
                setActivityReport(documentList)
        });

        fetchDocuments("ad6993f5-be0c-4e48-b203-35c4d075bf7a").then((documentList) => {
            if (documentList)
                setBudget(documentList)
        });

        fetchDocuments("4be3824d-3383-4383-bf04-ad85ffccc16a").then((documentList) => {
            if (documentList)
                setBalanceSheet(documentList)
        });

        fetchDocuments("4be3824d-3383-4383-bf04-ad85ffccc16a").then((documentList) => {
            if (documentList)
                setBalanceSheet(documentList)
        });

   

        fetchDocuments("f76dd8bd-aba8-45b8-9995-f0086729978d").then((documentList) => {
            if (documentList)
                setTrialBalance(documentList)
        });

        fetchDocuments("7f95d6ba-0c4d-4850-9d1a-e3393fb15306").then((documentList) => {
            if (documentList)
                setBudgetExecution(documentList)
        });

        fetchDocuments("0842f046-32ee-4dce-a90d-2a824bb7e9ed").then((documentList) => {
            if (documentList)
                setStaffingPlan(documentList)
        });

        fetchDocuments("4cf72333-e7fa-47d9-a635-0b8d1a53291c").then((documentList) => {
            if (documentList)
                setPositionsAndSalaries(documentList)
        });
        fetchDocuments("b30cf8ea-53e7-4c51-b248-e51913a1a65f").then((documentList) => {
            if (documentList)
                setOrganigrams(documentList);
        })
        fetchDocuments("e5db7360-5805-451e-9235-5d224366d771").then((documentList) => {
            if (documentList)
                setPublciInterestDocuments(documentList);
        })

        fetchDocuments("e71968fa-19a3-4408-9080-99ab3d2d53d0").then((documentList) => {
            if (documentList)
                setTemplateForms(documentList);
        })

        fetchDocuments("eca70dda-99c9-4df6-9ac5-01d6815368aa").then((documentList) => {
            if (documentList)
                setContestDecision(documentList);
        })

    }, []);

    const handleDownload = async (fileName: string, id: string) => {
        try {
            const response = await axiosService.getDocumentById(id);

            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className="about-us-page page">
            <SideMenu />
            <div className="page-content">
                <img className="img-content" src={aboutUsContent} />

                <div className="topic-container">
                    <div className="title">{t('about-us.page.baneasa-history.title')}</div>
                    <div className="section-content">
                        <p>{t('about-us.page.baneasa-history.part1')}</p>
                        <p>{t('about-us.page.baneasa-history.part2')}</p>
                        <p>{t('about-us.page.baneasa-history.part3')}</p>
                        <p>{t('about-us.page.baneasa-history.part4')}</p>
                        <p>{t('about-us.page.baneasa-history.part5')}</p>
                        <p>{t('about-us.page.baneasa-history.part6')}</p>
                        <p>{t('about-us.page.baneasa-history.part7')}</p>
                    </div>
                </div>
                <DropdownSection title={t('about-us.page.topic-1.title')}>
                    <div className="topic-container">
                        <div className="section-content">
                            <div className="subtitle-content">
                                <p className="subtitle">{t('about-us.page.topic-1.subtitle')}</p>
                                <p >{t('about-us.page.topic-1.strategies')}</p>
                            </div>
                            <ul>
                                <li className="download" onClick={() => handleDownload(t('about-us.page.topic-1.strategies.strategy-1'), '369373cb-1260-478c-9bee-c949597522d8')}><p>{t('about-us.page.topic-1.strategies.strategy-1')} </p> <DownloadIcon /></li>
                                <li className="download" onClick={() => handleDownload(t('about-us.page.topic-1.strategies.strategy-2'), '2d043c55-c25b-455e-8406-ad2aae65e45b')}><p>{t('about-us.page.topic-1.strategies.strategy-2')} </p><DownloadIcon /></li>
                                <li className="download" onClick={() => handleDownload(t('about-us.page.topic-1.strategies.strategy-3'), 'fe58c5fe-73a7-4326-a4d9-254eaade3c5e')}><p>{t('about-us.page.topic-1.strategies.strategy-3')} <DownloadIcon /></p></li>
                            </ul>
                            <p className="subtitle">{t('about-us.page.topic-1.general-objectives')}</p>
                            <ul>
                                <li><p>{t('about-us.page.topic-1.general-objective.objective-1')}</p></li>
                                <li><p>{t('about-us.page.topic-1.general-objective.objective-2')}</p></li>
                                <li><p>{t('about-us.page.topic-1.general-objective.objective-3')}</p></li>
                                <li><p>{t('about-us.page.topic-1.general-objective.objective-4')}</p></li>
                                <li><p>{t('about-us.page.topic-1.general-objective.objective-5')}</p></li>
                                <li><p>{t('about-us.page.topic-1.general-objective.objective-6')}</p></li>
                                <li><p>{t('about-us.page.topic-1.general-objective.objective-7')}</p></li>
                            </ul>
                            <p className="subtitle">{t('about-us.page.topic-1.strategic-research')}</p>
                            <ul>
                                <li><p>{t('about-us.page.topic-1.strategic-research.strategy-1')}</p></li>
                                <li><p>{t('about-us.page.topic-1.strategic-research.strategy-2')}</p></li>
                                <li><p>{t('about-us.page.topic-1.strategic-research.strategy-3')}</p></li>
                                <li><p>{t('about-us.page.topic-1.strategic-research.strategy-4')}</p></li>
                            </ul>
                            <div className="subtitle-content">
                                <p className="subtitle">{t('about-us.page.topic-1.own-strategy')}</p>
                                <p>{t('about-us.page.topic-1.own-strategy.subtitle')}</p>
                                <p className="subtitle">{t('about-us.page.topic-1.own-strategy.1.title')}</p>
                                <ul>
                                    <li><p>{t('about-us.page.topic-1.own-strategy.1.item-1')}</p></li>
                                    <li><p>{t('about-us.page.topic-1.own-strategy.1.item-2')}</p></li>
                                    <li><p>{t('about-us.page.topic-1.own-strategy.1.item-3')}</p></li>
                                    <li><p>{t('about-us.page.topic-1.own-strategy.1.item-4')}</p></li>
                                    <li><p>{t('about-us.page.topic-1.own-strategy.1.item-5')}</p></li>
                                    <li><p>{t('about-us.page.topic-1.own-strategy.1.item-6')}</p></li>
                                    <li><p>{t('about-us.page.topic-1.own-strategy.1.item-7')}</p></li>
                                </ul>
                                <p className="subtitle">{t('about-us.page.topic-1.own-strategy.2.title')}</p>
                                <ul>
                                    <li><p>{t('about-us.page.topic-1.own-strategy.2.1')}</p></li>
                                    <li><p>{t('about-us.page.topic-1.own-strategy.2.2')}</p></li>
                                    <li><p>{t('about-us.page.topic-1.own-strategy.2.3')}</p></li>
                                    <li><p>{t('about-us.page.topic-1.own-strategy.2.4')}</p></li>
                                    <li><p>{t('about-us.page.topic-1.own-strategy.2.5')}</p></li>
                                    <li><p>{t('about-us.page.topic-1.own-strategy.2.6')}</p></li>
                                    <li><p>{t('about-us.page.topic-1.own-strategy.2.7')}</p></li>
                                </ul>
                            </div>
                            <p className="subtitle">{t('about-us.page.topic-1.research-projects')}</p>
                            <ul>
                                <li><p>{t('about-us.page.topic-1.research-projects.project-1')}</p></li>
                                <li><p>{t('about-us.page.topic-1.research-projects.project-2')}</p></li>
                                <li><p>{t('about-us.page.topic-1.research-projects.project-3')}</p></li>
                                <li><p>{t('about-us.page.topic-1.research-projects.project-4')}</p></li>
                                <li><p>{t('about-us.page.topic-1.research-projects.project-5')}</p></li>
                                <li><p>{t('about-us.page.topic-1.research-projects.project-6')}</p></li>
                                <li><p>{t('about-us.page.topic-1.research-projects.project-7')}</p></li>
                            </ul>

                            <p className="subtitle">{t('about-us.page.topic-1.research-results')}</p>
                            <ul>
                                <li><p>{t('about-us.page.topic-1.research-results.1')}</p></li>
                                <li><p>{t('about-us.page.topic-1.research-results.2')}</p></li>
                                <li><p>{t('about-us.page.topic-1.research-results.3')}</p></li>
                            </ul>

                            <div className="subtitle-content">
                            <p className="subtitle">{t('about-us.page.topic-1.activity-report')}</p>
                            <div className="section-content">
                                <div className="flex-row flex-wrap gap-10">
                                    {programsActivityReport?.map(({ name, id }) => (
                                        <div key={id} className="download width-100 m-bt-5" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                            <p className="subtitle">{t('about-us.page.topic-1.date-info')}</p>
                            <ul>
                                <li><p>{t('about-us.page.topic-1.date-info.1')}</p></li>
                                <li><p>{t('about-us.page.topic-1.date-info.2')}</p></li>
                                <li><p>{t('about-us.page.topic-1.date-info.3')}</p></li>
                                <li><p>{t('about-us.page.topic-1.date-info.4')}</p></li>
                            </ul>
                            {/* <DownloadButton theme="white" label={t('about-us.page.topic-1.download-button')} /> */}
                        </div>
                    </div>
                </DropdownSection>
                <DropdownSection title={t('about-us.page.topic-2.title')}>
                    <div className="topic-container">
                        <div className="section-content">
                            <ul>
                                <li onClick={() => { window.open("https://legislatie.just.ro/Public/DetaliiDocument/103997", "_blank") }}><p className="download">{t('about-us.page.topic-2.part-1')}</p></li>
                                <li onClick={() => { window.open("https://legislatie.just.ro/Public/DetaliiDocumentAfis/129151", "_blank") }}><p className="download">{t('about-us.page.topic-2.part-2')}</p></li>
                                <li onClick={() => { window.open("https://legislatie.just.ro/Public/DetaliiDocument/31413", "_blank") }}><p className="download">{t('about-us.page.topic-2.part-3')}</p></li>
                                <li onClick={() => { window.open("https://legislatie.just.ro/Public/DetaliiDocument/207078", "_blank") }}><p className="download">{t('about-us.page.topic-2.part-4')}</p></li>
                            </ul>
                        </div>
                    </div>
                </DropdownSection>
                <DropdownSection title={t('about-us.page.topic-3.title')}>
                    <div className="topic-container">
                        <div className="section-content">
                            <p className="subtitle">{t('about-us.page.topic-3.subtitle')}</p>
                            <ul>
                                <li><p>{t('about-us.page.topic-3.info.1')}</p></li>
                                <li><p>{t('about-us.page.topic-3.info.2')}</p></li>
                                <li><p>{t('about-us.page.topic-3.info.3')}</p></li>
                                <li className="download">
                                    <p
                                        onClick={() => {
                                            window.location.href = 'tel:+40212330613'
                                        }}
                                    >{t('about-us.page.topic-3.info.4')}
                                    </p>
                                </li>
                                <li className="download">
                                    <p
                                        onClick={() => {
                                            window.location.href = `mailto:secretariat@scdpbaneasa.ro`;
                                        }}
                                    >
                                        {t('about-us.page.topic-3.info.5')}</p></li>
                                <li><p>{t('about-us.page.topic-3.info.6')}</p></li>
                            </ul>
                        </div>
                        <div className="subtitle-content">
                            <p className="subtitle">{t('about-us.page.topic-3.subtitle')}</p>
                            <div className="section-content">
                                <div className="flex-row flex-wrap flex-align-space-between-center">
                                    {regulations?.map(({ name, id }) => (
                                        <div key={id} className="download width-40 m-bt-10" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </div>
                                    ))}
                                </div>
                                <div className="flex-row flex-wrap gap-10">
                                    {ogranigrams?.map(({ name, id }) => (
                                        <div key={id} className="download width-100 m-bt-5" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="subtitle-content">
                            <p className="subtitle">{t('about-us.page.topic-3.management-structure')}</p>
                            <ul>
                                <li className="download" onClick={() => handleDownload(t('about-us.page.topic-3.management-structure.management-1'), '883556e0-9b19-4456-aa2c-2d37368e83a7')}><p>{t('about-us.page.topic-3.management-structure.management-1')} <DownloadIcon /></p></li>
                                <li className="download" onClick={() => handleDownload(t('about-us.page.topic-3.management-structure.management-2'), 'bda01193-3008-4cd9-815a-5bd645ebf7d0')}><p>{t('about-us.page.topic-3.management-structure.management-2')} <DownloadIcon /></p></li>
                                <li className="download" onClick={() => handleDownload(t('about-us.page.topic-3.management-structure.management-3'), '2ae8a316-6674-46f9-bf35-9494db7aaa2c')}><p>{t('about-us.page.topic-3.management-structure.management-3')} <DownloadIcon /></p></li>
                            </ul>
                        </div>
                        <div className="subtitle-content">
                            <p className="subtitle">{t('about-us.page.topic-3.directors-board')}</p>
                            <ul>
                                <li><p>{t('about-us.page.topic-3.directors-board.director-1')}</p></li>
                            </ul>
                        </div>
                        <div className="subtitle-content">
                            <p className="subtitle">{t('about-us.page.topic-3.members')}</p>
                            <div className="section-content">
                                <ul>
                                    <li><p>{t('about-us.page.topic-3.members.member-1')}</p></li>
                                    <li><p>{t('about-us.page.topic-3.members.member-2')}</p></li>
                                    <li><p>{t('about-us.page.topic-3.members.member-3')}</p></li>
                                    <li><p>{t('about-us.page.topic-3.members.member-4')}</p></li>
                                </ul>
                            </div>
                        </div>
                        <div className="subtitle-content">
                            <p className="subtitle">{t('about-us.page.topic-3.responsible')}</p>
                            <div className="section-content">
                                <ul>
                                    <li><p>{t('about-us.page.topic-3.responsible.person')}</p></li>
                                </ul>
                            </div>
                        </div>

                        <div className="subtitle-content">
                            <p className="subtitle">{t('about-us.page.topic-3.assets.title')}</p>
                            <div className="flex-row gap-10">
                                <div className="flex-column align-center gap-10">
                                    <p className="m-bt-10 year-subtitle">2024</p>
                                    {assets2024?.map(({ name, id }) => (
                                        <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </li>
                                    ))}
                                </div>
                                <div className="flex-column align-center gap-10">
                                    <p className="m-bt-10 year-subtitle">2025</p>
                                    {assets2025?.map(({ name, id }) => (
                                        <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </li>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="subtitle-content">
                            <p className="subtitle">{t('about-us.page.topic-3.gdpr.title')}</p>
                            <div className="section-content">
                                {gdpr?.map(({ name, id }) => (
                                    <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                        <p>{name} <DownloadIcon /></p>
                                    </li>
                                ))}
                            </div>
                        </div>

                        <div className="subtitle-content">
                            <p className="subtitle">{t('about-us.page.topic-3.integrity.title')}</p>
                            <div className="section-content">

                                {integrity?.map(({ name, id }) => (
                                    <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                        <p>{name} <DownloadIcon /></p>
                                    </li>
                                ))}
                            </div>
                        </div>
                        
                    </div>
                </DropdownSection>
                <DropdownSection title={t('about-us.page.topic-7.title')}>
                    <div className="topic-container">
                        <div className="section-content">
                            <p className="subtitle">{t('about-us.page.topic-7.subtitle')}</p>
                            <ul>
                                <li>
                                    <p className="download"
                                        onClick={() => {
                                            window.location.href = `mailto:secretariat@scdpbaneasa.ro`;
                                        }}>{t('about-us.page.topic-7.info.1')}</p></li>
                                <li>
                                    <p className="download"
                                        onClick={() => {
                                            window.location.href = 'tel:+40212330613'
                                        }}
                                    >{t('about-us.page.topic-7.info.2')}</p></li>
                                <li><p>{t('about-us.page.topic-7.info.3')}</p></li>

                            </ul>
                        </div>
                        <div className="subtitle-content">
                            <p className="subtitle">{t('about-us.page.topic-7.section.1')}</p>
                            <div className="section-content">
                                <div className="flex-row flex-wrap gap-20">
                                    {publicInterestDocuments?.map(({ name, id }) => (
                                        <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </li>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="subtitle-content">
                            <p className="subtitle">{t('about-us.page.topic-7.section.2')}</p>
                            <div className="section-content">
                                <div className="flex-row flex-wrap gap-20">
                                    {templateForms?.map(({ name, id }) => (
                                        <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </li>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="subtitle-content">
                            <p className="subtitle">{t('about-us.page.topic-7.section.3')}</p>
                            <div className="section-content">
                                <div className="flex-row flex-wrap gap-20">
                                    {contestDecision?.map(({ name, id }) => (
                                        <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </li>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </DropdownSection>
                <DropdownSection title={t('about-us.page.topic-4.title')}>
                    <div className="topic-container">
                        <div className="subtitle-content">
                            <div className="section-content">
                                <p className="subtitle">{t('about-us.page.topic-4.1.title')}</p>
                                <ul>
                                    <li className="download" onClick={() => handleDownload(t('about-us.page.topic-4.1.1'), '993e02db-203a-4a11-ad56-4de1c8bd0c84')}><p>{t('about-us.page.topic-4.1.1')} <DownloadIcon /></p></li>
                                </ul>
                            </div>
                            <div className="section-content">
                                <p className="subtitle">{t('about-us.page.topic-4.2.title')}</p>
                                <div className="flex-row flex-wrap gap-20">
                                    {paap?.map(({ name, id }) => (
                                        <div key={id} className="download m-bt-5" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="section-content">
                                <p className="subtitle">{t('about-us.page.topic-4.3.title')}</p>
                                <div className="flex-row flex-wrap gap-20">
                                    {procurementRecordsOver5000?.map(({ name, id }) => (
                                        <div key={id} className="download m-bt-5" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="section-content">
                                <p className="subtitle">{t('about-us.page.topic-4.4.title')}</p>
                                <div className="flex-row flex-wrap gap-20">
                                    {procurementRecords?.map(({ name, id }) => (
                                        <div key={id} className="download m-bt-5" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="section-content">
                                <p className="subtitle">{t('about-us.page.topic-4.5.title')}</p>
                                <div className="flex-row flex-wrap gap-20">
                                    {anualProcurementRecords?.map(({ name, id }) => (
                                        <div key={id} className="download m-bt-5" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <NavButton label={t('button.navigation.archive')} path="/archive"/>
                    </div>
                </DropdownSection>
                <DropdownSection title={t('about-us.page.topic-5.title')}>
                    <div className="topic-container">
                        <div className="subtitle-content">
                            <div className="section-content">
                                <div className="flex-row flex-wrap gap-20">
                                    {activityReport?.map(({ name, id }) => (
                                        <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </li>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <NavButton label={t('button.navigation.archive')} path="/archive"/>
                    </div>
                </DropdownSection>
                <DropdownSection title={t('about-us.page.topic-6.title')}>
                    <div className="topic-container">
                        <div className="subtitle-content">

                            <div className="section-content">
                                <div className="flex-row space-between flex-wrap gap-20">
                                    <div className="flex-column gap-10">
                                        <p className="subtitle m-bt-10">{t('about-us.page.topic-6.1')}</p>
                                        {budget.map(({ name, id }) => (
                                            <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                                <p>{name} <DownloadIcon /></p>
                                            </li>
                                        ))}
                                    </div>
                                    <div className="flex-column gap-10">
                                        <p className="subtitle m-bt-10">{t('about-us.page.topic-6.2')}</p>
                                        {balanceSheet.map(({ name, id }) => (
                                            <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                                <p>{name} <DownloadIcon /></p>
                                            </li>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="subtitle-content m-bt-10">
                            <div className="flex-row space-between flex-wrap gap-20">
                                <div className="flex-column gap-10 m-bt-10">
                                    <p className="subtitle">{t('about-us.page.topic-6.3')}</p>
                                    <div className="section-content">
                                        {trialBalance.map(({ name, id }) => (
                                            <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                                <p>{name} <DownloadIcon /></p>
                                            </li>
                                        ))}
                                    </div>
                                </div>
                                <div className="flex-column gap-10">
                                    <p className="subtitle">{t('about-us.page.topic-6.4')}</p>
                                    <div className="section-content">
                                        {budgetExecution.map(({ name, id }) => (
                                            <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                                <p>{name} <DownloadIcon /></p>
                                            </li>
                                        ))}
                                    </div>
                                </div>
                                <div className="flex-column gap-10">
                                    <p className="subtitle">{t('about-us.page.topic-6.5')}</p>
                                    <div className="section-content">
                                        {staffingPlan.map(({ name, id }) => (
                                            <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                                <p>{name} <DownloadIcon /></p>
                                            </li>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        <div className="subtitle-content">
                            <p className="subtitle">{t('about-us.page.topic-6.6')}</p>
                            <div className="section-content">
                                {positionsAndSalaries.map(({ name, id }) => (
                                    <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                        <p>{name} <DownloadIcon /></p>
                                    </li>
                                ))}
                            </div>
                            
                        </div>
                        <div className="subtitle-content">
                            <p className="subtitle">{t('about-us.page.topic-6.7')}</p>
                            <div className="section-content">
                                <ul>
                                    <li className="download" onClick={() => handleDownload(t('about-us.page.topic-6.7.1'), '70e39c84-e8bd-462f-866a-fac221be5c6a')}><p>{t('about-us.page.topic-6.7.1')} <DownloadIcon /></p></li>
                                </ul>
                            </div>
                        </div>
                        <NavButton label={t('button.navigation.archive')} path="/archive"/>
                    </div>
                </DropdownSection>
            </div>
        </div>
    )
}

export default AboutUsPage;