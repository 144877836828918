import { Outlet, useLocation } from "react-router-dom";
import './app-layout.scss';
import AppHeader from "./app-header";
import AppFooter from "./app-footer";
import { useState, useEffect } from "react";
import AppMobileHeader from "./app-mobile-header";
import ImageModal from "./image-modal";

const AppLayout: React.FC = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const location = useLocation();


    const isBase2 = location.pathname === '/development/base-2';
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1300);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);

    
    return (
        <div className="app-layout">
            {isMobile ? <AppMobileHeader/> : <AppHeader/>}
            <Outlet/>
           {!isBase2 && <AppFooter/>} 
           <ImageModal />
        </div>
    )
} 
export default AppLayout; 