import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowIcon} from '../assets/icons/arrow-white.svg';
import './nav-button.scss';

interface NavButtonProps {
   label: string;
   path: string;
}

const NavButton: React.FC<NavButtonProps> = ({label, path}) => {

    const navigate = useNavigate();

    return (
        <div
            className='navigation-button'
            onClick={() => {
                navigate(path)
                setTimeout(() => {
                    const element = document.querySelector('.app-header') || document.querySelector('.app-mobile-header');
                    if (element) {
                        element.scrollIntoView({ behavior: "smooth" });
                    } 
                }, 100);
            }
            }
        >
            {label}
            <ArrowIcon />
        </div>
    )
}

export default NavButton;