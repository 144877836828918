import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import roFlag from '../assets/icons/ro-flag.svg';
import enFlag from '../assets/icons/en-flag.svg';
import './switch-language-mobile.scss';

const languageOptions = [
    { label: 'Română', value: 'ro', flag: roFlag },
    { label: 'English', value: 'en', flag: enFlag },
];

const SwitchLanguageMobile = () => {
    const { i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState('ro');

    const handleLanguageChange = (language: string) => {
        i18n.changeLanguage(language);
        localStorage.setItem('selectedLanguage', language);
        setSelectedLanguage(language);
    };

    return (
        <div className="switch-language-mobile">
            {languageOptions.map(lang => (
                <button
                    key={lang.value}
                    className={`language-button ${selectedLanguage === lang.value ? 'active' : ''}`}
                    onClick={() => handleLanguageChange(lang.value)}
                >
                    <img src={lang.flag} alt={lang.label} className="language-flag" />
                    {lang.label}
                </button>
            ))}
        </div>
    );
};

export default SwitchLanguageMobile;
