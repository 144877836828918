// context/ImageModalContext.tsx
import React, { createContext, useContext, useState } from "react";

interface ImageModalContextType {
  showImage: (src: string) => void;
  hideImage: () => void;
  imageSrc: string | null;
}

const ImageModalContext = createContext<ImageModalContextType | undefined>(undefined);

export const ImageModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const showImage = (src: string) => setImageSrc(src);
  const hideImage = () => setImageSrc(null);

  return (
    <ImageModalContext.Provider value={{ showImage, hideImage, imageSrc }}>
      {children}
    </ImageModalContext.Provider>
  );
};

export const useImageModal = () => {
  const context = useContext(ImageModalContext);
  if (!context) {
    throw new Error("useImageModal must be used within an ImageModalProvider");
  }
  return context;
};
