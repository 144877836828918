import { ReactComponent as HandIcon } from '../../assets/icons/lab-1.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import './home-page.section.banner.scss';
import { useTranslation } from 'react-i18next';

const bannerBg = require('../../assets/images/home-banner-bg.png');

const partners = {
    "home-page.banner.partners.authorities": [
        { name: "home-page.banner.partners.authorities.madr", url: "https://www.madr.ro/" },
        { name: "home-page.banner.partners.authorities.afir", url: "https://www.afir.ro/" },
        { name: "home-page.banner.partners.authorities.rrn", url: "https://www.rrn-pac.eu/" },
        { name: "home-page.banner.partners.authorities.scar-akis", url: "https://scar-europe.org/akis-documents" },
        { name: "home-page.banner.partners.authorities.pai-agri", url: "https://www.payagri.com/" }
    ],
    "home-page.banner.partners.asas-network": [
        { name: "home-page.banner.partners.asas-network.asas", url: "https://www.asas.ro/" },
        { name: "home-page.banner.partners.asas-network.icdp", url: "https://icdp.ro/ro/" },
        { name: "home-page.banner.partners.research-stations.scdp-voinesti", url: "https://www.scdpvoinesti.ro/" },
        { name: "home-page.banner.partners.research-stations.scdp-constanta", url: "https://scdpconstanta.ro/" },
        { name: "home-page.banner.partners.research-stations.scdp-bistrita", url: "https://www.scdp-bistrita.ro/" },
        { name: "home-page.banner.partners.research-stations.scdp-iasi", url: "https://pomicolaiasi.ro/" }
    ],
    // "home-page.banner.partners.research-stations": [
    //     { name: "home-page.banner.partners.research-stations.scdp-voinesti", url: "https://www.scdpvoinesti.ro/" },
    //     { name: "home-page.banner.partners.research-stations.scdp-constanta", url: "https://scdpconstanta.ro/" },
    //     { name: "home-page.banner.partners.research-stations.scdp-bistrita", url: "https://www.scdp-bistrita.ro/" },
    //     { name: "home-page.banner.partners.research-stations.scdp-iasi", url: "https://pomicolaiasi.ro/" }
    // ],
    "home-page.banner.partners.education": [
        { name: "home-page.banner.partners.education.usamv", url: "https://usamv.ro/" },
        { name: "home-page.banner.partners.education.politehnica", url: "https://upb.ro/facultati/facultatea-de-energetica/" },
        { name: "home-page.banner.partners.education.liceul-brancusi", url: "https://www.c-brancusi.ro/" }
    ],
    "home-page.banner.partners.projects": [
        { name: "home-page.banner.partners.projects.organic-climatenet", url: "https://organicclimatenet.eu/" },
        { name: "home-page.banner.partners.projects.climate-farm-demo", url: "https://climatefarmdemo.eu/" }
    ],
    "home-page.banner.partners.international-partners": [
        { name: "home-page.banner.partners.international-partners.czech", url: "https://www.vsuo.cz/cs/" },
        { name: "home-page.banner.partners.international-partners.tanta-university", url: "https://agr.tanta.edu.eg/en/" },
        { name: "home-page.banner.partners.international-partners.vmu", url: "https://www.vdu.lt/en/" }
    ],
    "home-page.banner.partners.media": [
        { name: "home-page.banner.partners.media.radio-antena-satelor", url: "https://www.antenasatelor.ro/" },
        { name: "home-page.banner.partners.media.tvr-viata-satului", url: "http://tvr1.tvr.ro/emisiuni/viata-satului_7176.html" },
        { name: "home-page.banner.partners.media.agrotv", url: "https://agro-tv.ro/" }
    ],
    "home-page.banner.partners.commercial-partners": [
        { name: "home-page.banner.partners.commercial-partners.dronebox", url: "https://www.dronebox.ro/" },
        { name: "home-page.banner.partners.commercial-partners.riagro", url: "https://droneagricole.ro/" },
        { name: "home-page.banner.partners.commercial-partners.calin-robotics", url: "https://www.calinrobotics.ro/" }
    ]
};

const HomePageSectionBanner = () => {
    const { t } = useTranslation();

    return (
        <div className="homepage-banner">
            <div className="background-image">
                <img src={bannerBg} alt="" />
            </div>

            <div className="overlay">
                <div className="content">
                    <div className="icon-title-container">
                        <HandIcon />
                        <span className="title">{t('home-page.banner.title')}</span>
                    </div>

                    <div className="partners-list">
                        {Object.entries(partners).map(([category, links]) => (
                            <div key={category} className="partner-category">
                                <h3>{t(category)}</h3>
                                <ul>
                                    {links.map((partner, index) => (
                                        <li key={index}>
                                            <a href={partner.url} target="_blank" rel="noopener noreferrer">
                                                {t(partner.name)}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePageSectionBanner;
