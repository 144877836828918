import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import './home-page.section.events.scss';
import CarouselFade from '../../components/carousel-fade';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LogoSvg } from '../../assets/icons/logo-baneasa.svg';


interface Item {
    title: string;
}

const HomePageSectionEvents = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();


    const handleNavigate = (path: string) => {
        
        navigate(path);
        setTimeout(() => {
            const element = document.querySelector('.app-header') || document.querySelector('.app-mobile-header');
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                } 
        }, 200);
    }
    const carouselItems = Array.from({ length: 2 }).map((_, i) => {
        const index = i + 3; 
        let img;
        try {
            img = require(`../../assets/images/event-${index}.png`);
        } catch (e) {
            img = '';
        }
        return (
            <div
                className='item'
                key={index}
                onClick={() => {
                    handleNavigate(`/events/event-${index}`);
                }}
            >
                <div className='image-container'>
                    {img ? (
                        <img src={img} alt={'No Image'} />
                    ) : (
                        <LogoSvg className='img-svg'/>
                    )}
                    <div className='arrow-container'>
                        <ArrowIcon />
                    </div>
                </div>
            </div>
        );
    });

    return (
        <div className="homepage-events">
            <CarouselFade
                theme='light'
                helperText={t('home-page.events.latest-news')}
                title={t('home-page.events.title')}
                items={carouselItems}
            />
        </div>
    );
};

export default HomePageSectionEvents;
