import { ReactComponent as LogoBaneasa } from '../assets/icons/logo-baneasa.svg';
import { ReactComponent as SearchIcon } from '../assets/icons/search.svg';
import { ReactComponent as BurgerMenu } from '../assets/icons/buger-menu.svg';
import './app-mobile-header.scss';
import HeaderMobileInfoSection from './header-mobile-info-section';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SwitchLanguage from './switch-language';
import SwitchLanguageMobile from './switch-language-mobile';





const AppMobileHeader = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

 

    const navigation = [
        { label: t('header.navigation-1'), path: '/home' },
        { label: t('header.navigation-2'), path: '/about-us' },
        {
            label: t('header.navigation-3'),
            subPaths: [
                { label: t('header.navigation-3.item-1'), path: '/research#laboratories' },
                { label: t('header.navigation-3.item-2'), path: '/research#national-projects' },
                { label: t('header.navigation-3.item-3'), path: '/research#resources-panel' },
                { label: t('header.navigation-3.item-4'), path: '/research#performances' },
            ]
        },
        {
            label: t('header.navigation-4'),
            subPaths: [{ label: t('header.navigation-4.item-1'), path: 'development' }]
        },
        { label: t('header.navigation-5'), externalPath: 'https://magazin.baneasa.realgrowsoft.com' },
        { label: t('header.navigation-6'), path: '/events' },
        { label: t('header.navigation-7'), path: '/services' },
        { label: t('header.navigation-8'), path: '/announcements' },
    ];

    useEffect(() => {
        return () => {
            document.body.style.overflow = 'auto'; 
        };
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen((prev) => {
            if (!prev) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
            }
            return !prev;
        });
    };

    const handleNavigate = (path: string) => {
        if (path.includes("#")) {
            const [basePath, hash] = path.split("#");
            navigate(basePath);
            toggleMenu();
            setTimeout(() => {
                const element = document.getElementById(hash);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }, 100); 
        } else {
            toggleMenu();
            setIsMenuOpen(false);
            navigate(path);
            setTimeout(() => {
                const element = document.querySelector('.app-header') || document.querySelector('.app-mobile-header');
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                } 
            }, 100);
        }
    }

    return (
        <div className="app-mobile-header">
            <div className="logo-menu-container">
                <div className="logo">
                    <LogoBaneasa width={90} height={90} />
                </div>
                <div className="search-burger-menu-container">
                    {/* <div className="search">
                        <SearchIcon />
                    </div> */}
                    <div className="burger-menu" onClick={toggleMenu}>
                        <BurgerMenu />
                    </div>
                </div>
            </div>
            <div className={`mobile-navigation ${isMenuOpen ? 'open' : 'closed'}`}>
                <div className="close-button" onClick={toggleMenu}>
                    ✕
                </div>
                {navigation.map((navItem, index) => (
                    <div key={index} className="nav-item">
                        <div
                            className="main-item"
                            onClick={() => {
                                if (navItem.path) {
                                    handleNavigate(navItem.path)
                                } else { 
                                    window.open(navItem.externalPath!, "_blank")
                                    toggleMenu()
                                }

                            }}
                        >
                            {navItem.label}
                        </div>
                        {navItem.subPaths && (
                            <div className="subpaths-container">
                                {navItem.subPaths.map((subPath, subIndex) => (
                                    <div
                                        key={subIndex}
                                        className="subpath-item"
                                        onClick={() => {
                                            handleNavigate(subPath.path)
                                        }}
                                    >
                                        {subPath.label}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
                <div className='switch-language-container'>
                <SwitchLanguageMobile />
                </div>
                
            </div>
            <HeaderMobileInfoSection />
        </div>
    );
};

export default AppMobileHeader;
