import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from '../assets/icons/arrow.svg';
import './header-info-section.scss';
import { useTranslation } from 'react-i18next';

const HeaderInfoSection = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const pathSegments = location.pathname.split('/').filter(Boolean);

    let translationKey = 'home';  

    if (pathSegments[0] === 'research' && pathSegments[1]?.startsWith('national-projects')) {
        const projectGroup = pathSegments[1].split('-')[2];  
        const projectId = pathSegments[2] || '1';            

        translationKey = `national-projects-${projectGroup}.${projectId}`;
    } else if (pathSegments[0] === 'events' && pathSegments[1]?.startsWith('workshop')) {
        const projectGroup = pathSegments[1].split('-')[2];  
        const projectId = pathSegments[2] || '1';     
               

        translationKey = `workshop-${projectId}`;
    } else {
        translationKey = pathSegments[pathSegments.length - 1] || 'home';
    }


    const isMadr = ['national-projects-1.1', 'national-projects-1.2', 'national-projects-1.3'].includes(translationKey);
    const isPNDR = ['national-projects-2.1', 'national-projects-2.2', 'national-projects-2.3'].includes(translationKey);

    const isHome = translationKey === 'home';

    return (
        <div className='info-section-container'>
            <div className="section">
                 {isHome && <div className='title-help-text'> <span>{t(`header.${translationKey}.badge-text`)}</span></div>}
                <div className='title'>{t(`header.${translationKey}.title`)}</div>
                <div className='separator'></div>
                {(isMadr || isPNDR) && <div className='subtitle'>{t(`header.${translationKey}.description`)}</div>}

                {/* {isHome && (
                    <div className='details-container' style={{cursor: 'pointer'}} onClick={() => navigate('about-us')}>
                        <span>{t('header.home.button')}</span>
                        <ArrowIcon />
                    </div>
                )} */}
            </div>
        </div>
    );
};

export default HeaderInfoSection;
