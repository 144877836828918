import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import "./app-map.scss";

interface AppMapProps {
    latitude: number;
    longitude: number;
}

const mapContainerStyle = {
    width: "100%",
    height: "300px",
};



const AppMap: React.FC<AppMapProps> = ({ latitude, longitude }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyBDjnXOZTlw0jAnAgBJP-FrQnY9_9TdDqs'
    });

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading maps...</div>;
    const markerIcon = {
        url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png", 
        scaledSize: new window.google.maps.Size(50, 50), 
    };

    return (
        <div className="app-map-container">
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={{ lat: latitude, lng: longitude }}
                zoom={14}
            >
                <MarkerF 
                position={{ lat: latitude, lng: longitude }} 
                icon={markerIcon} 
                onClick={() => {
                    const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
                    window.open(url, "_blank");
                }}
                />
            </GoogleMap>
        </div>
    );
};

export default AppMap;
