import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as LeafIcon } from '../../assets/icons/leaf.svg';
import { ReactComponent as ActiveArrow } from '../../assets/icons/arrow-active.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import './bases.side-menu.subbases.scss';

const SideMenuSubBases: React.FC = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const baseIds = ['base-1', 'base-2']; // Add more as needed

    // Build the base project data from translations
    const bases = baseIds.map((id) => ({
        id,
        title: t(`side-menu.bases.${id}.title`),
        description: t(`side-menu.bases.${id}.description`),
    }));


    return (
        <div className="side-menu-sub-bases-container">
            <div className="title-wrapper">
                <LeafIcon width={30} height={30} />
                <span className="title">{t('side-menu.bases.title')}</span>
            </div>
            <div className="content">
                {bases.map((base) => {
                    const isActive = location.pathname.includes(`/development/${base.id}`);
                    return (
                        <div
                            key={base.id}
                            className={`item ${isActive ? 'active' : ''}`}
                            onClick={() => navigate(`/development/${base.id}`)}
                        >
                            <div className="text-container">
                                <span className="bolded">{base.title}</span>
                                <span style={{ whiteSpace: 'pre-line' }}>
                                    {base.description}
                                </span>
                            </div>
                            <div>
                                {isActive ? <ActiveArrow width={25} height={25} /> : <ArrowIcon width={25} height={25} />}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default SideMenuSubBases;
