import { useTranslation } from 'react-i18next';
import SideMenu from '../../components/side-menu';
import './services-page.scss';
import NumberIndicator from '../../components/number-indicator';
import ContactForm from '../../components/contact-form';
import { ReactComponent as DocumentIcon } from '../../assets/icons/document.svg';


const ServicesPage = () => {

    const { t } = useTranslation();

    const sections = Array.from({ length: 8 });

    return (
        <div className="services-page page">
            <SideMenu />
            <div className="page-content">
                <div className="topic-container">
                    <div className="title">{t('services.page.title')}</div>

                    <p className="normal-text">{t('services.page.description')}</p>
                    <p className="normal-text">{t('services.page.instructions')}</p>

                    <div className="subtitle-content">
                        <p className="subtitle">{t('services.page.steps.title')}</p>
                        <ol>
                            <li><p>{t('services.page.steps.step1')}</p></li>
                            <li className="download" onClick={() => { window.open("https://docs.google.com/forms/d/e/1FAIpQLSdAco08Omhuq8UC7Kng_NapiEoHNa4_oH5A0OG5uY6S1u6epA/viewform","_blank")}}><p> <DocumentIcon /> {t('services.page.steps.step2')} </p></li>
                            <li><p>{t('services.page.steps.step3')}</p></li>
                            <li><p>{t('services.page.steps.step4')}</p></li>
                            <li><p>{t('services.page.steps.step5')}</p></li>
                        </ol>
                        <p className="normal-text">{t('services.page.notes')}</p>
                        <ol start={6}>
                            <li><p>{t('services.page.steps.step6')}</p></li>
                        </ol>
                    </div>
                </div>
                <div className='separator'></div>
                <div className='sections'>
                    {
                        sections.map((_, index) => (
                            <div className='section' key={index}>
                                <NumberIndicator number={index + 1} />
                                <div className="subtitle-content">
                                    <p className="subtitle">
                                        {t(`services.page.sections.section-${index + 1}.title`)}
                                    </p>

                                    <ul>
                                        {(t(`services.page.sections.section-${index + 1}.description`, { returnObjects: true }) as string[]).map((text, idx) => (
                                            <li key={idx}>
                                                <p>
                                                    {index === 0 && idx === 0 ? (
                                                        <a className='download' href="https://legislatie.just.ro/Public/DetaliiDocumentAfis/45273" target="_blank" rel="noopener noreferrer">
                                                            {text}
                                                        </a>
                                                    ) :  index === 0 && idx === 1 ? (
                                                        <a className='download' href="https://www.afir.ro/finantare/finantare-in-agricultura/pomicultura/" target="_blank" rel="noopener noreferrer">
                                                            {text}
                                                        </a>
                                                    ) : text}
                                                </p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className='separator'></div>
                <ContactForm />
            </div>
        </div>
    )
}

export default ServicesPage;