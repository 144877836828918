import { useTranslation } from "react-i18next";
import axiosService from "../../services/axios.service";
import './archive-page.scss';
import DropdownSection from "../../components/dropdown-section";
import { ReactComponent as DownloadIcon } from '../../assets/icons/download-small.svg';
import { useEffect, useState } from "react";

const ArchivePage = () => {

    const [programsActivityReport, setProgramActivityReport] = useState<{ name: string; id: string }[]>([]);
    const [ogranigrams, setOrganigrams] = useState<{ name: string; id: string }[]>([]);
    const [assets2018, setAssets2018] = useState<{ name: string; id: string }[]>([]);
    const [assets2019, setAssets2019] = useState<{ name: string; id: string }[]>([]);
    const [assets2020, setAssets2020] = useState<{ name: string; id: string }[]>([]);
    const [assets2021, setAssets2021] = useState<{ name: string; id: string }[]>([]);
    const [assets2022, setAssets2022] = useState<{ name: string; id: string }[]>([]);
    const [assets2023, setAssets2023] = useState<{ name: string; id: string }[]>([]);
    const [publicInterestDocuments, setPublciInterestDocuments] = useState<{ name: string, id: string }[]>([]);
    const [paap, setPaap] = useState<{ name: string; id: string }[]>([]);
    const [procurementRecordsOver5000, setProcurementRecordsOver5000] = useState<{ name: string; id: string }[]>([]);
    const [procurementRecords, setProcurementRecords] = useState<{ name: string; id: string }[]>([]);
    const [anualProcurementRecords, setAnualProcurementRecords] = useState<{ name: string; id: string }[]>([]);
    const [activityReport, setActivityReport] = useState<{ name: string; id: string }[]>([]);
    const [budget, setBudget] = useState<{ name: string; id: string }[]>([]);
    const [balanceSheet, setBalanceSheet] = useState<{ name: string; id: string }[]>([]);
    const [trialBalance, setTrialBalance] = useState<{ name: string; id: string }[]>([]);
    const [budgetExecution, setBudgetExecution] = useState<{ name: string; id: string }[]>([]);
    const [staffingPlan, setStaffingPlan] = useState<{ name: string; id: string }[]>([]);
    const [positionsAndSalaries, setPositionsAndSalaries] = useState<{ name: string; id: string }[]>([]);
    const [announcements, setAnnouncemets] = useState<{name: string; id: string}[]>( []);

    const { t } = useTranslation();

    useEffect(() => {
        const fetchDocuments = async (nodeId: string) => {
            try {
                const response = await axiosService.getListOfDocumentsByNodeId(nodeId);
                const documentList = response.data.list.entries.map((entry: any) => ({
                    name: entry.entry.name.replace(/\.(pdf|docx)$/i, ""),
                    id: entry.entry.id
                }));
                return documentList;

            } catch (error) {
                console.error("Error fetching documents:", error);
            }
        };

        fetchDocuments("f66b9418-c108-4aa7-9d67-433ceef89510").then((documentList) => {
            if (documentList)
                setProgramActivityReport(documentList);
        })

        fetchDocuments("6313292c-83fb-4256-ae15-6c3e31a17354").then((documentList) => {
            if (documentList)
                setOrganigrams(documentList);
        })

        fetchDocuments("b3cd6acd-1524-4975-8b3b-b83cf2de9b84").then((documentList) => {
            if (documentList)
                setAssets2018(documentList)
        });

        fetchDocuments("ec5d6ecb-55c5-4ea3-b930-579a3d3811be").then((documentList) => {
            if (documentList)
                setAssets2019(documentList)
        });

        fetchDocuments("6911f02b-c7e0-427f-a07d-c3caac7b7096").then((documentList) => {
            if (documentList)
                setAssets2020(documentList)
        });


        fetchDocuments("8cd4f67d-d658-427c-b488-2d7a64c442c5").then((documentList) => {
            if (documentList)
                setAssets2021(documentList)
        });

        fetchDocuments("bbe626c6-dba2-4d18-a831-aedbfe3df477").then((documentList) => {
            if (documentList)
                setAssets2022(documentList)
        });

        fetchDocuments("9f071ea3-9e5e-4eab-a870-c0f915072e84").then((documentList) => {
            if (documentList)
                setAssets2023(documentList)
        });

        fetchDocuments("f8ecccfc-75c6-4def-a435-e4e12ca636fc").then((documentList) => {
            if (documentList)
                setPublciInterestDocuments(documentList);
        })

        fetchDocuments("1628824e-262f-4155-9666-04272a710e8f").then((documentList) => {
            if (documentList)
                setPaap(documentList)
        });

        fetchDocuments("b0b1d84e-7b5c-484a-946b-373fb3d0e98e").then((documentList) => {
            if (documentList)
                setProcurementRecordsOver5000(documentList)
        });

        fetchDocuments("5758da77-4b8a-48aa-85b0-6a1f56f20214").then((documentList) => {
            if (documentList)
                setProcurementRecords(documentList)
        });

        fetchDocuments("076b955d-78fc-4e7b-a93f-867d99623976").then((documentList) => {
            if (documentList)
                setAnualProcurementRecords(documentList)
        });

        fetchDocuments("df42ad1e-b40d-4b6d-9b80-a99e1ac30110").then((documentList) => {
            if (documentList)
                setActivityReport(documentList)
        });

        fetchDocuments("7d72a6eb-c31d-4bdb-a2fe-65d684d2f1b4").then((documentList) => {
            if (documentList)
                setBudget(documentList)
        });

        fetchDocuments("a8d0e2de-6a92-4a4a-a867-4a94f86ac190").then((documentList) => {
            if (documentList)
                setBalanceSheet(documentList)
        });



        fetchDocuments("a182885d-a7c1-416b-9f9e-ac195cabfd7a").then((documentList) => {
            if (documentList)
                setTrialBalance(documentList)
        });

        fetchDocuments("0600a753-b5c8-4998-8c91-ecdc4ec31eee").then((documentList) => {
            if (documentList)
                setBudgetExecution(documentList)
        });

        fetchDocuments("a36c2a5b-eef5-4faa-9773-b15a86eeed23").then((documentList) => {
            if (documentList)
                setStaffingPlan(documentList)
        });

        fetchDocuments("0a0c099d-b8dd-4731-8ad9-ffd0c3e3d036").then((documentList) => {
            if (documentList)
                setPositionsAndSalaries(documentList)
        });

        fetchDocuments("c89a807b-0064-4a54-8cb3-d0199ab13b11").then((documentList) => {
            if (documentList)
                setAnnouncemets(documentList)
        });
        
    }, []);


    const handleDownload = async (fileName: string, id: string) => {
        try {
            const response = await axiosService.getDocumentById(id);

            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className="archive-page page">
            <div className="page-content">
                <DropdownSection title={t('about-us.page.topic-1.title')}>
                    <div className="topic-container">
                        <div className="subtitle-content">
                            <p className="subtitle">{t('about-us.page.topic-1.activity-report')}</p>
                            <div className="section-content">
                                <div className="flex-row flex-wrap gap-10">
                                    {programsActivityReport?.map(({ name, id }) => (
                                        <div key={id} className="download width-100 m-bt-5" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </DropdownSection>
                <DropdownSection title={t('about-us.page.topic-3.title')}>
                    <div className="topic-container">
                        <div className="subtitle-content">
                            <p className="subtitle">{t('about-us.page.topic-3.subtitle')}</p>
                            <div className="section-content">
                                <div className="flex-row flex-wrap gap-40">
                                    {ogranigrams?.map(({ name, id }) => (
                                        <div key={id} className="download width-100 m-bt-5" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>


                        <div className="subtitle-content">
                            <p className="subtitle">{t('about-us.page.topic-3.assets.title')}</p>
                            <div className="flex-row gap-20 flex-align-space-between-start flex-wrap">
                            <div className="flex-column align-items gap-10">
                                    <p className="m-bt-10 year-subtitle">2018</p>
                                    {assets2018?.map(({ name, id }) => (
                                        <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </li>
                                    ))}
                                </div>
                                <div className="flex-column align-items gap-10">
                                    <p className="m-bt-10 year-subtitle">2019</p>
                                    {assets2019?.map(({ name, id }) => (
                                        <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </li>
                                    ))}
                                </div>
                                <div className="flex-column align-items gap-10">
                                    <p className="m-bt-10 year-subtitle">2020</p>
                                    {assets2020?.map(({ name, id }) => (
                                        <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </li>
                                    ))}
                                </div>
                                <div className="flex-column align-items gap-10">
                                    <p className="m-bt-10 year-subtitle">{t('about-us.page.topic-3.assets.2021')}</p>
                                    {assets2021?.map(({ name, id }) => (
                                        <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </li>
                                    ))}
                                </div>
                                <div className="flex-column align-items gap-10">
                                    <p className="m-bt-10 year-subtitle">{t('about-us.page.topic-3.assets.2022')}</p>
                                    {assets2022?.map(({ name, id }) => (
                                        <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </li>
                                    ))}
                                </div>
                                <div className="flex-column align-items gap-10">
                                    <p className="m-bt-10 year-subtitle">{t('about-us.page.topic-3.assets.2023')}</p>
                                    {assets2023?.map(({ name, id }) => (
                                        <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </li>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </div>
                </DropdownSection>
                <DropdownSection title={t('about-us.page.topic-7.title')}>
                    <div className="topic-container">
                        <div className="subtitle-content">
                            <p className="subtitle">{t('about-us.page.topic-7.section.1')}</p>
                            <div className="section-content">
                                <div className="flex-row flex-wrap gap-20">
                                    {publicInterestDocuments?.map(({ name, id }) => (
                                        <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </li>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </DropdownSection>
                <DropdownSection title={t('about-us.page.topic-4.title')}>
                    <div className="topic-container">
                        <div className="subtitle-content">
                            <div className="section-content">
                                <p className="subtitle">{t('about-us.page.topic-4.2.title')}</p>
                                <div className="flex-row flex-wrap gap-20">
                                    {paap?.map(({ name, id }) => (
                                        <div key={id} className="download m-bt-5" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="section-content">
                                <p className="subtitle">{t('about-us.page.topic-4.3.title')}</p>
                                <div className="flex-row flex-wrap gap-20">
                                    {procurementRecordsOver5000?.map(({ name, id }) => (
                                        <div key={id} className="download m-bt-5" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="section-content">
                                <p className="subtitle">{t('about-us.page.topic-4.4.title')}</p>
                                <div className="flex-row flex-wrap gap-20">
                                    {procurementRecords?.map(({ name, id }) => (
                                        <div key={id} className="download m-bt-5" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="section-content">
                                <p className="subtitle">{t('about-us.page.topic-4.5.title')}</p>
                                <div className="flex-row flex-wrap gap-20">
                                    {anualProcurementRecords?.map(({ name, id }) => (
                                        <div key={id} className="download m-bt-5" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </DropdownSection>
                <DropdownSection title={t('about-us.page.topic-5.title')}>
                    <div className="topic-container">
                        <div className="subtitle-content">
                            <div className="section-content">
                                <div className="flex-row flex-wrap gap-20">
                                    {activityReport?.map(({ name, id }) => (
                                        <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </li>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </DropdownSection>
                <DropdownSection title={t('about-us.page.topic-6.title')}>
                    <div className="topic-container">
                        <div className="subtitle-content">

                            <div className="section-content">
                                <div className="flex-column gap-20">
                                    <div className="flex-row gap-20 flex-wrap">
                                        <p className="subtitle m-bt-10">{t('about-us.page.topic-6.1')}</p>
                                        {budget.map(({ name, id }) => (
                                            <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                                <p>{name} <DownloadIcon /></p>
                                            </li>
                                        ))}
                                    </div>
                                    <div className="flex-row gap-20 flex-wrap">
                                        <p className="subtitle m-bt-10">{t('about-us.page.topic-6.2')}</p>
                                        {balanceSheet.map(({ name, id }) => (
                                            <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                                <p>{name} <DownloadIcon /></p>
                                            </li>
                                        ))}
                                    </div>
                    
                                </div>
                            </div>
                        </div>

                        <div className="subtitle-content m-bt-10">
                            <div className="flex-column flex-wrap gap-20">
                                <div className="flex-row gap-20 flex-wrapm-bt-10">
                                    <p className="subtitle">{t('about-us.page.topic-6.3')}</p>
                                    <div className="flex-row gap-20 flex-wrap">
                                        {trialBalance.map(({ name, id }) => (
                                            <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                                <p>{name} <DownloadIcon /></p>
                                            </li>
                                        ))}
                                    </div>
                                </div>
                                <div className="flex-row gap-20 flex-wrap">
                                    <p className="subtitle">{t('about-us.page.topic-6.4')}</p>
                                    <div className="flex-row gap-20 flex-wrap">
                                        {budgetExecution.map(({ name, id }) => (
                                            <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                                <p>{name} <DownloadIcon /></p>
                                            </li>
                                        ))}
                                    </div>
                                </div>
                                <div className="flex-row gap-20 flex-wrap">
                                    <p className="subtitle">{t('about-us.page.topic-6.5')}</p>
                                    <div className="flex-row gap-20 flex-wrap">
                                        {staffingPlan.map(({ name, id }) => (
                                            <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                                <p>{name} <DownloadIcon /></p>
                                            </li>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="subtitle-content">
                            <p className="subtitle">{t('about-us.page.topic-6.6')}</p>
                            <div className="flex-row gap-20 flex-wrap">
                                {positionsAndSalaries.map(({ name, id }) => (
                                    <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                        <p>{name} <DownloadIcon /></p>
                                    </li>
                                ))}
                            </div>
                        </div>
                        <div className="subtitle-content">
                            <p className="subtitle">{t('about-us.page.topic-6.7')}</p>
                            <div className="section-content">
                                <ul>
                                    <li className="download" onClick={() => handleDownload(t('about-us.page.topic-6.7.1'), '70e39c84-e8bd-462f-866a-fac221be5c6a')}><p>{t('about-us.page.topic-6.7.1')} <DownloadIcon /></p></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </DropdownSection>

                <DropdownSection title={t('announcements.page.title-1')}>
                    <div className="topic-container">
                        <div className="subtitle-content">
                            <div className="section-content">
                                <div className="announcements-container ">
                                    {announcements?.map(({ name, id }) => (
                                        <li key={id} className="download" onClick={() => handleDownload(name, id)}>
                                            <p>{name} <DownloadIcon /></p>
                                        </li>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </DropdownSection>
            </div>
        </div>
    )
}

export default ArchivePage;