import { RouteObject } from "react-router-dom";
import EventsPage from "./events-page";
import Event1Page from "./event-1/event-1-page";
import WorkshopLayout from "./event-1/workshop.layout";
import Event3Page from "./event-3/event-3-page";
import Event2Page from "./event-2/event-2-page";
import Event4Page from "./event-4/event-4-page";

export const eventsRouter: RouteObject[] = [
    {
        path: '',
        element: <EventsPage/>
    }, 
    {
        path: 'event-1',
        element: <Event1Page/>
    },
    {
        path: 'event-2',
        element: <Event2Page/>
    },
    {
        path: 'event-3',
        element: <Event3Page/>
    },
    {
        path: 'event-4',
        element: <Event4Page/>
    },
    {
        path: '/events/workshop/:id',
        element: <WorkshopLayout/>
    }
] 