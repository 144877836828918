import { useTranslation } from "react-i18next";
import SideMenu from "../../../components/side-menu";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CheckIcon } from '../../../assets/icons/verified-yellow.svg';
import './event-2-page.scss';


// const eventMainImage = require('../../../assets/images/event-2/event-2-main.png');

const Event2Page = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNavigate = (index: number) => {
        navigate(`/events/workshop/${index}`)
    }

    const images = [6, 9, 3, 11, 14, 8];

    return (
        <div className="event-2-page page">
            <SideMenu showPublicInterest={false} />
            <div className="page-content">
                {/* <img src={eventMainImage} /> */}
                <div className="topic-container">
                    <div className="title">{t('events.page.event-2.title')}</div>
                    <div className="subtitle-content">
                        <div className="subtitle ">{t('events.page.event-2.date')}</div>
                    </div>
                    <div className="subtitle-content">
                        <div className="subtitle ">{t('events.page.event-4.location')}</div>
                    </div>
                    <div className="subtitle-content">
                        <div className="subtitle ">{t('events.page.event-2.activities.title')}</div>
                        <ul>
                            {Array.from({ length: 5 }).map((_, index) => (
                                <li><CheckIcon /><p>{t(`events.page.event-2.activities.item-${index + 1}`)}</p></li>
                            ))}
                        </ul>
                    </div>
                    <div className="subtitle-content">
                        <div className="subtitle ">{t('events.page.event-2.benefits.title')}</div>
                        <ul>
                            {Array.from({ length: 4 }).map((_, index) => (
                                <li><CheckIcon /><p>{t(`events.page.event-2.benefits.item-${index + 1}`)}</p></li>
                            ))}
                        </ul>
                        <div className="normal-text">{t("events.page.event-2.description-3")}</div>
                    </div>
                    <div className="subtitle-content">
                        <div className="subtitle ">{t('events.page.event-2.details.title')}</div>
                        {Array.from({ length: 4 }).map((_, idx) => {
                            return (
                                <div className="normal-text">{t(`events.page.event-2.details.text-${idx + 1}`)}</div>
                            )
                        })}
                    </div>
                    <div className="subtitle-content">
                        <div className="subtitle ">{t('events.page.event-2.program.list.title')}</div>
                        <ul>
                            {Array.from({ length: 6 }).map((_, index) => (
                                <div key={index}>
                                    <li><CheckIcon /><p>{t(`events.page.event-2.program.list.item-${index + 1}`)}</p></li>
                                    <div className='gallery-container'>
                                        {
                                            Array.from({ length: images[index] }).map((_, imgIdx) => {
                                                const img = require(`../../../assets/images/event-2/${index + 1}/gallery-${imgIdx + 1}.png`);
                                                return <img key={imgIdx} src={img} />
                                            })
                                        }

                                    </div>
                                </div>
                            ))}

                        </ul>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default Event2Page;