import { AxiosResponse } from "axios";
import { axiosInstance } from "../utils/axios.utils"
import { ApiListResponse, List } from "./axios.service-types";


const getDocumentById = (documentId: string) => {
    const response = axiosInstance.get(`nodes/${documentId}/content?attachment=true`,{
        responseType: 'blob', 
});
    
    return response;
}

const getListOfDocumentsByNodeId = (nodeId: string): Promise<AxiosResponse<ApiListResponse>> => {
    const response = axiosInstance.get(`nodes/${nodeId}/children`);
    return response;
}



export default { getDocumentById, getListOfDocumentsByNodeId }