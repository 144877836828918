import { ReactComponent as PdfIcon } from '../assets/icons/pdf.svg';
import { ReactComponent as DownloadIcon } from '../assets/icons/download.svg';
import { ReactComponent as LeafIcon } from '../assets/icons/leaf.svg';
import axiosService from '../services/axios.service';
import './download-button.scss';



interface DownloadButtonProps {
    theme: 'white' | 'yellow' | 'green';
    label: string;
    fileId?: string;
    fileName?: string;
    useLeafIcon?: boolean
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ theme, label, fileId, fileName, useLeafIcon }) => {

       const handleDownload = async (fileName: string, id: string) => {
                try {
                    const response = await axiosService.getDocumentById(id);
            
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL(blob);
                    window.open(url, '_blank');
                } catch (e) {
                    console.log(e);
                }
            };

    
    return (
        <div className={`download-button  ${theme}`}>
            {useLeafIcon ? <LeafIcon width={20} height={20}/> :  <PdfIcon />}
            <span>{label}</span>
            <div
                onClick={() => {
                    if(fileId && fileName) {
                        handleDownload(fileName, fileId)
                    }
                }}
                className='download-container'
            >
                <DownloadIcon width={26} height={26} />
            </div>
        </div>
    )
}

export default DownloadButton;