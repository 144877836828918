import React, { useEffect, useRef } from "react";
import "./image-modal.scss"; 
import { useImageModal } from "../context/image.modal.context";

const ImageModal: React.FC = () => {
  const { imageSrc, hideImage } = useImageModal();
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        hideImage();
      }
    };
    if (imageSrc) {
      document.addEventListener("mousedown", handleOutsideClick);
    }
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [imageSrc, hideImage]);

  if (!imageSrc) return null;

  return (
    <div className="image-modal-backdrop">
      <div className="image-modal-content" ref={modalRef}>
        <img src={imageSrc} alt="Preview" />
        <button className="close-button" onClick={hideImage}>×</button>
      </div>
    </div>
  );
};

export default ImageModal;
