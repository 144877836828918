import { useTranslation } from 'react-i18next';
import { ReactComponent as HandIcon } from '../assets/icons/lab-1.svg';
import { ReactComponent as PhoneIcon } from '../assets/icons/phone.svg';
import { ReactComponent as ArrowIcon } from '../assets/icons/arrow.svg';

import './info-contact-card.scss';
import { useNavigate } from 'react-router-dom';

const infoContactBg = require('../assets/images/info-contact-card-bg.png');



const InfoContactCard = () => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    return (
        <div className="info-contact-card-container">
            <img src={infoContactBg} alt="" />
            <div className='content'>
                <HandIcon />
                <div className='title'>{t('side-menu.contact-card.title')}</div>
                <div
                    className='phone'
                    onClick={() => {
                        window.location.href = 'tel:+40212330613'
                    }}
                >
                    <PhoneIcon width={20} height={20} />
                    +40 212 330 613
                </div>
                <div
                    className='contact-button'
                    onClick={() => navigate('/contact')}
                >
                    Contact
                    <ArrowIcon />
                </div>
            </div>
        </div>
    )
}

export default InfoContactCard;