import './app-header.scss';
import { ReactComponent as LogoBaneasa } from '../assets/icons/logo-baneasa.svg';
import { ReactComponent as PhoneIcon } from '../assets/icons/phone.svg';
import { ReactComponent as ArrowIcon } from '../assets/icons/arrow.svg';
import HeaderInfoSection from './header-info-section';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import DropdownMenu from './dropdown-menu';
import { useTranslation } from 'react-i18next';
import SwitchLanguage from './switch-language';

import bgHome from '../assets/images/home-bg.png';
import bgAboutUs from '../assets/images/about-us-bg.png';
import bgResearch from '../assets/images/research-bg.png';
import bgDevelopment from '../assets/images/development-bg.png';
import bgEvents from '../assets/images/events-bg.png';
import bgServices from '../assets/images/services-bg.png';
import bgAnnouncements from '../assets/images/announcements-bg.png';
import bgContact from '../assets/images/contact-bg.png';

import bgNationalProject1 from '../assets/images/national-projects-1/1-bg.png';
import bgNationalProject2 from '../assets/images/national-projects-1/2-bg.png';

import bgLaboratory1 from '../assets/images/laboratory-1-bg.png';
import bgLaboratory2 from '../assets/images/laboratory-2-bg.png';

import bgBase1 from '../assets/images/base-1-bg.png';
import bgBase2 from '../assets/images/base-2-bg.png';

import bgWorkshop1 from '../assets/images/workshop-1/1-bg.png';
import bgWorkshop2 from '../assets/images/workshop-2/2-bg.png';
import bgWorkshop3 from '../assets/images/workshop-3/3-bg.png';
import bgWorkshop4 from '../assets/images/workshop-4/4-bg.png';

import bgEvent1 from '../assets/images/event-1-bg.png';
import bgEvent2 from '../assets/images/event-2-bg.png';
import bgEvent3 from '../assets/images/event-3-bg.png';
import bgEvent4 from '../assets/images/event-4-bg.png';

const routeBackgrounds: Record<string, string> = {
    'home': bgHome,
    'about-us': bgAboutUs,
    'research': bgResearch,
    'development': bgDevelopment,
    'events': bgEvents,
    'services': bgServices,
    'announcements': bgAnnouncements,
    'contact': bgContact,

    'laboratory-1': bgLaboratory1,
    'laboratory-2': bgLaboratory2,

    'base-1': bgBase1,
    'base-2': bgBase2,

    'project-1': bgNationalProject1,
    'project-2': bgNationalProject2,

    'workshop-1': bgWorkshop1,
    'workshop-2': bgWorkshop2,
    'workshop-3': bgWorkshop3,
    'workshop-4': bgWorkshop4,

    'event-1': bgEvent1,
    'event-2': bgEvent2,
    'event-3': bgEvent3,
    'event-4': bgEvent4,
};

const AppHeader = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const isHome = location.pathname === '/home';

    const [backgroundImage, setBackgroundImage] = useState('');
    const [bgLoaded, setBgLoaded] = useState(false);
    const [showFloatingNav, setShowFloatingNav] = useState(false);
    const navbarRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        Object.values(routeBackgrounds).forEach((src) => {
            const img = new Image();
            img.src = src;
        });
    }, []);

    useEffect(() => {
        const pathSegments = location.pathname.split('/').filter(Boolean);
        let key = '';

        if (pathSegments[1]?.startsWith('national-projects')) {
            key = pathSegments[1] === 'national-projects-1' ? 'project-1' : 'project-2';
        } else if (pathSegments[1] === 'workshop') {
            key = `workshop-${pathSegments[2]}`;
        } else if (pathSegments[1]) {
            key = pathSegments[1];
        } else {
            key = pathSegments[0] || 'home';
        }

        const matchedImage = routeBackgrounds[key] || bgHome;
        setBgLoaded(false);

        const img = new Image();
        img.src = matchedImage;
        img.onload = () => {
            setBackgroundImage(matchedImage);
            setBgLoaded(true);
        };
    }, [location.pathname]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setShowFloatingNav(!entry.isIntersecting);
            },
            { root: null, threshold: 0 }
        );

        if (navbarRef.current) observer.observe(navbarRef.current);
        return () => {
            if (navbarRef.current) observer.unobserve(navbarRef.current);
        };
    }, []);

    const handleNavigate = (path: string) => {
        if (path.includes("#")) {
            const [basePath, hash] = path.split("#");
            navigate(basePath);
            setTimeout(() => {
                const element = document.getElementById(hash);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }, 100);
        } else {
            navigate(path);
            setTimeout(() => {
                const element = document.querySelector('.app-header') || document.querySelector('.app-mobile-header');
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }, 100);
        }
    };

    const navigation = [
        { label: t('header.navigation-1'), path: '/home' },
        { label: t('header.navigation-2'), path: '/about-us' },
        {
            label: t('header.navigation-3'),
            subPaths: [
                { label: t('header.navigation-3.item-1'), path: '/research#laboratories' },
                { label: t('header.navigation-3.item-2'), path: '/research#national-projects' },
                { label: t('header.navigation-3.item-3'), path: '/research#resources-panel' },
                { label: t('header.navigation-3.item-4'), path: '/research#performances' },
            ]
        },
        {
            label: t('header.navigation-4'),
            subPaths: [{ label: t('header.navigation-4.item-1'), path: 'development' }]
        },
        { label: t('header.navigation-5'), externalPath: 'https://magazin.baneasa.realgrowsoft.com' },
        { label: t('header.navigation-6'), path: '/events' },
        { label: t('header.navigation-7'), path: '/services' },
        { label: t('header.navigation-8'), path: '/announcements' },
    ];

    return (
        <header className={`app-header ${isHome ? 'home' : ''}`}>
            <div className={`overlay ${bgLoaded ? 'loaded' : ''}`} style={{ backgroundImage: `url(${backgroundImage})` }}>
                <div className='shadow-overlay'></div>
                <div className='navbar-section'>
                    <div className='logo-container'><LogoBaneasa /></div>
                    <div className='navbar-container'>
                        <div className="navigation" ref={navbarRef}>
                            {navigation.map((navItem, index) => (
                                <div key={index} className="nav-item">
                                    {navItem.subPaths ? (
                                        <DropdownMenu
                                            label={navItem.label}
                                            items={navItem.subPaths.map((subItem) => ({
                                                label: subItem.label,
                                                onClick: () => handleNavigate(subItem.path)
                                            }))}
                                        />
                                    ) : (
                                        <span onClick={() => navItem.path ? handleNavigate(navItem.path) : window.open(navItem.externalPath!, "_blank")}>
                                            {navItem.label}
                                        </span>
                                    )}
                                    {index !== navigation.length - 1 && <div className="yellow-dot"></div>}
                                </div>
                            ))}
                        </div>
                        <div className='call-search-container'>
                            <PhoneIcon />
                            <div className='call-text-container'>
                                <span style={{ cursor: 'pointer' }} onClick={() => { window.location.href = 'tel:+40212330613' }}>
                                    +40 212 330 613
                                </span>
                            </div>
                        </div>
                        <div><SwitchLanguage /></div>
                    </div>
                    <div className='contact-us-container' onClick={() => navigate('/contact')}>
                        <div className='button-container'>
                            <span>Contact</span>
                            <ArrowIcon />
                        </div>
                    </div>
                </div>
                <HeaderInfoSection />
            </div>

            <div className={`floating-navigation ${showFloatingNav ? 'visible' : ''}`}>
                <div className='navbar-section'>
                    <div className='logo-container'><LogoBaneasa /></div>
                    <div className='navbar-container'>
                        <div className="navigation">
                            {navigation.map((navItem, index) => (
                                <div key={index} className="nav-item">
                                    {navItem.subPaths ? (
                                        <DropdownMenu
                                            label={navItem.label}
                                            items={navItem.subPaths.map((subItem) => ({
                                                label: subItem.label,
                                                onClick: () => handleNavigate(subItem.path)
                                            }))}
                                        />
                                    ) : (
                                        <span onClick={() => navItem.path ? handleNavigate(navItem.path) : window.open(navItem.externalPath!, "_blank")}>
                                            {navItem.label}
                                        </span>
                                    )}
                                    {index !== navigation.length - 1 && <div className="yellow-dot"></div>}
                                </div>
                            ))}
                        </div>
                        <div className='call-search-container'>
                            <PhoneIcon />
                            <div className='call-text-container'>
                                <span style={{ cursor: 'pointer' }} onClick={() => { window.location.href = 'tel:+40212330613' }}>
                                    +40 212 330 613
                                </span>
                            </div>
                        </div>
                        <div style={{ visibility: 'hidden' }}><SwitchLanguage /></div>
                    </div>
                    <div className='contact-us-container' onClick={() => navigate('/contact')}>
                        <div className='button-container'>
                            <span>Contact</span>
                            <ArrowIcon />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default AppHeader;
