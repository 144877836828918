import { useTranslation } from "react-i18next";
import { useMemo, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SideMenuProjects from "./national-projects.side-menu";
import './national-projects-1.scss';
import axiosService from "../../../services/axios.service";
import { useImageModal } from "../../../context/image.modal.context";

interface Topic {
    label: string;
    key: string;
}

interface ImageModel {
    imgSrc: string;
}

const NationalProjects1Page: React.FC = () => {

    const { showImage } = useImageModal();

    const { t } = useTranslation();
    const { projectId = '1' } = useParams();
    const navigate = useNavigate();

    const [galleryImages, setGalleryImages] = useState<ImageModel[]>([]);

    const subMenuItems: Topic[] = useMemo(() => {
        const items: Topic[] = [];

        for (let i = 1; i <= 7; i++) {
            const label = t(`plan-1.projects.${projectId}.submenu.${i}.label`);

            if (label && label !== `plan-1.projects.${projectId}.submenu.${i}.label`) {
                items.push({ label, key: `${i}` });
            }
        }
        return items;
    }, [projectId, t]);

    const [activeTopic, setActiveTopic] = useState<Topic>(subMenuItems[0]);

    useEffect(() => {
        if (!activeTopic && subMenuItems.length > 0) {
            setActiveTopic(subMenuItems[0]);
        }
    }, [subMenuItems, activeTopic]);

    const handleChangeTopic = (topic: Topic) => {
        setActiveTopic(topic);
        if (topic.label === "Galerie" || topic.label === 'Gallery') {
            loadGalleryImages();
        } else {
            setGalleryImages([]);
        }
    };

    const loadGalleryImages = () => {
        try {
            const loadedImages: ImageModel[] = [];
            for (let i = 1; i <= 10; i++) {
                try {
                    const imgSrc = require(`../../../assets/images/national-projects-1/${projectId}/gallery-${i}.png`);
                    loadedImages.push({ imgSrc });
                } catch (error) {
                    console.warn(`Image gallery-${i}.png not found for project ${projectId}`);
                }
            }
            setGalleryImages(loadedImages);
        } catch (error) {
            console.error("Error loading gallery images", error);
        }
    };



    const renderContent = (content: unknown) => {
        if (Array.isArray(content)) {
            return (
                <ul>
                    {content.map((item, index) => {
                        // if (projectId == '1' && activeTopic.label == 'Rapoarte și documente' && index === 0) {
                        //     return <li key={index}
                        //         onClick={() => {
                                    
                        //         }}
                        //     ><p>{String(item)}</p>
                        //     </li>
                        // }
                        return (
                            <li key={index}
                            ><p>{String(item)}</p>
                            </li>
                        )
                    })}
                </ul>
            );
        } else if (typeof content === "string") {
            return <p className="normal-text">{content}</p>;
        } else {
            return <p className="normal-text">No content available.</p>;
        }
    };


    const content = t(`plan-1.projects.${projectId}.submenu.${activeTopic.key}.content`, { returnObjects: true }) as string | string[];



    return (
        <div className="national-projects-1-page page">
            <SideMenuProjects />
            <div className="page-content">
                <div className="topic-container">
                    <div className="title">{t(`plan-1.projects.${projectId}.title`)}</div>
                    <div className="subtitle-content">
                        <p className="subtitle">{t(`plan-1.projects.${projectId}.description`)}</p>
                    </div>
                    <div className="submenu-container">
                        {subMenuItems.map((item, index) => (
                            <button
                                key={index}
                                className={`submenu-item ${activeTopic.key === item.key ? 'active' : ''}`}
                                onClick={() => handleChangeTopic(item)}
                            >
                                {item.label}
                            </button>
                        ))}
                    </div>

                    <div className="subtitle-content">
                        {activeTopic?.label === "Galerie" || activeTopic?.label === "Gallery" ? (
                            <div className="gallery-container">
                                {galleryImages.length > 0 ? (
                                    galleryImages.map((image, index) => (
                                        <img key={index} src={image.imgSrc} alt={`Gallery ${index + 1}`} className="gallery-image" onClick={() => showImage(image.imgSrc)}/>
                                    ))
                                ) : (
                                    <p></p>
                                )}
                            </div>
                        ) : (
                            renderContent(content)
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NationalProjects1Page;
