import ContactForm from '../../components/contact-form';
import SideMenu from '../../components/side-menu';
import { ReactComponent as PdfIcon } from '../../assets/icons/pdf-small.svg';
import './announcements-page.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import axiosService from '../../services/axios.service';
import NavButton from '../../components/nav-button';

const AnnouncementsPage = () => {
    const { t } = useTranslation();
    const [expandedTable, setExpandedTable] = useState<string | null>(null);
    const [announcements, setAnnouncements] = useState<{ name: string; id: string }[]>([]);
    const [acquisitions, setAcquisitions] = useState<{ name: string; id: string }[]>([]);

    const toggleTable = (table: string) => {
        setExpandedTable(expandedTable === table ? null : table);
    };

    useEffect(() => {
        const fetchDocuments = async (nodeId: string) => {
            try {
                const response = await axiosService.getListOfDocumentsByNodeId(nodeId);
                const documentList = response.data.list.entries.map((entry: any) => ({
                    name: entry.entry.name.replace(".pdf", ""),
                    id: entry.entry.id
                }));
                return documentList;

            } catch (error) {
                console.error("Error fetching documents:", error);
            }
        };

        fetchDocuments("356aa1d0-74fc-441f-a813-5084909e3048").then((documentList) => {
            if (documentList)
                setAnnouncements(documentList);
        })

        fetchDocuments("6a5fcf4b-c2fe-43b2-8706-7d544650b56a").then((documentList) => {
            if (documentList)
                setAcquisitions(documentList);
        })

    }, [])

    // const announcements = Object.keys(t('announcements.page.table-1', { returnObjects: true }))
    //     .filter(key => key.startsWith('row-'))
    //     .map((key) => ({
    //         name: t(`announcements.page.table-1.${key}.name`)
    //     }));

    // const acquisitions = Object.keys(t('announcements.page.table-2', { returnObjects: true }))
    //     .filter(key => key.startsWith('row-'))
    //     .map((key) => ({
    //         name: t(`announcements.page.table-2.${key}.name`)
    //     }));

     const handleDownload = async (fileName: string, id: string) => {
             try {
                 const response = await axiosService.getDocumentById(id);
         
                 const blob = new Blob([response.data], { type: 'application/pdf' });
                 const url = window.URL.createObjectURL(blob);
                 window.open(url, '_blank');
             } catch (e) {
                 console.log(e);
             }
    };

    return (
        <div className="announcements-page page">
            <SideMenu showJobApplication />
            <div className="page-content">
                <div className="topic-container">
                    <div className="title" onClick={() => toggleTable('announcements')}>
                        {t('announcements.page.title-1')} <span>{expandedTable === 'announcements' ? '▲' : '▼'}</span>
                    </div>
                    {expandedTable === 'announcements' && (
                        <div className="announcements-table">
                            {announcements.map((item, index) => (
                                <div 
                                key={index} 
                                className="announcement-row" 
                                onClick={() => handleDownload(item.name,item.id)}
                                >
                                    <div className="row-header">
                                        {item.name}
                                        <PdfIcon />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div className="topic-container">
                    <div className="title" onClick={() => toggleTable('acquisitions')}>
                        {t('announcements.page.title-2')} {expandedTable === 'acquisitions' ? '▲' : '▼'}
                    </div>
                    {expandedTable === 'acquisitions' && (
                        <div className="announcements-table">
                             {acquisitions.map((item, index) => (
                                <div 
                                key={index} 
                                className="announcement-row" 
                                onClick={() => handleDownload(item.name,item.id)}
                                >
                                    <div className="row-header">
                                        {item.name}
                                        <PdfIcon />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <NavButton label={t('button.navigation.archive')} path="/archive"/>

                <ContactForm />
            </div>
        </div>
    );
};

export default AnnouncementsPage;