import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as Verified } from '../../assets/icons/verified-yellow.svg';
import DownloadButton from '../../components/download-button';
import ResearchPagePartLaboratories from './research-page.part.laboratory';
import DownloadItem from '../../components/download-item';
import ResearchPagePartProjects from './research-page.part.projects';
import { ReactComponent as PdfIcon } from '../../assets/icons/pdf.svg';
import './research-page.scss';
import axiosService from '../../services/axios.service';
import { useEffect, useState } from 'react';
import DropdownSection from '../../components/dropdown-section';


const assortmentImage = require('../../assets/images/assortment-peaches-and-nectarines.png');






const nectarine = [
    { label: 'Tina' },
    { label: 'Mihaela' }
]

const catalogISTS = {
    label: 'Descarca Catalog'
}



const ResearchPage = () => {

    const { t } = useTranslation();

    const [apricot, setApricot] = useState<{ name: string; id?: string }[]>([]);
    const [peach, setPeach] = useState<{ name: string; id?: string }[]>([]);
    const [publications, setPublications] = useState<{ name: string; id?: string }[]>([]);
    const [activityReports, setActivityReports] = useState<{ name: string; id?: string }[]>([]);
    const [regstry, setRegistry] = useState<{ name: string; id?: string }[]>([]);

    const staticApricots = [
        { name: 'Olimp' },
        { name: 'Excelsior' },
        { name: 'Dacia' },
        { name: 'Comandor' },
        { name: 'Favorit' },
        { name: 'Valeria' },
        { name: 'Nicusor' },
        { name: 'Adina' }
    ];

    const staticPeach = [
        { name: 'Flacara' },
        { name: 'Splendid' },
        { name: 'Superba de toamna' },
        { name: 'Congres' },
        { name: 'Triumf' },
        { name: 'Victoria' },
        { name: 'Antonia' },
        { name: 'Amalia' },
        { name: 'Alexia' },
        { name: 'Eugen' },
        { name: 'Dida' }
    ];

    const researchItems = [
        t('research.page.topic-1.section-1.item-1'),
        t('research.page.topic-1.section-1.item-2'),
        t('research.page.topic-1.section-1.item-3'),
        t('research.page.topic-1.section-1.item-4'),
    ]

    const infrastructureItems = [
        {
            text: t('research.page.topic-1.section-2.list.item-1')
        },
        {
            text: t('research.page.topic-1.section-2.list.item-2'),
            image: require('../../assets/images/eeris.png')
        },
        {
            text: t('research.page.topic-1.section-2.list.item-3'),
            image: require('../../assets/images/up2europe.png')
        },
        {
            text: t('research.page.topic-1.section-2.list.item-4')
        },
    ]

    useEffect(() => {
        const fetchDocuments = async (nodeId: string) => {
            try {
                const response = await axiosService.getListOfDocumentsByNodeId(nodeId);
                const documentList = response.data.list.entries.map((entry: any) => ({
                    name: entry.entry.name.replace(".pdf", ""),
                    id: entry.entry.id
                }));
                return documentList;

            } catch (error) {
                console.error("Error fetching documents:", error);
            }
        };
        fetchDocuments("6c42c067-e676-4f74-8a60-588af06495d2").then((documentList) => {
            if (documentList)
                setApricot([...staticApricots, ...documentList]);
        })

        fetchDocuments("d36271f9-fabd-45c5-a564-a2e7744dad4c").then((documentList) => {
            if (documentList)
                setPeach([...staticPeach, ...documentList]);
        })

        fetchDocuments("cde4f3e1-c851-4988-82d1-f9f44afe4fbf").then((documentList) => {
            if (documentList)
                setPublications(documentList);
        })

        fetchDocuments("9d27f4b9-67b7-421e-b117-c7b4a1956525").then((documentList) => {
            if (documentList)
                setActivityReports(documentList);
        })

        fetchDocuments("b09820b2-2b7a-4c4b-bf48-dc36d6a5b509").then((documentList) => {
            if (documentList)
                setRegistry(documentList);
        })

    }, [])



    const handleDownload = async (fileName: string, id: string) => {
        try {
            const response = await axiosService.getDocumentById(id);

            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className="research-page page">
            <div className="page-content">
                <div id="laboratories">
                    <ResearchPagePartLaboratories />
                </div>
                <div className='research-topic-container'>
                    <div className='subtopic-container'>
                        <div className='title'>{t('research.page.topic-1.title')}</div>
                        <div className='description'>{t('research.page.topic-1.section-1.description')}</div>
                        <div className='research-section'>
                            {
                                researchItems.map((item, idx) => (
                                    <div key={idx} className='research-item'>
                                        <Verified /> <span>{item}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className='subtopic-container'>
                        <div className='title hidden'>{t('research.page.topic-1.title')}</div>
                        <div
                            className='description bolded download'
                            onClick={() => handleDownload(t('research.page.topic-1.section-2.description'), 'f3e5ef44-15fa-4517-91cf-c49201c11496')}
                        >{t('research.page.topic-1.section-2.description')} <PdfIcon />
                        </div>
                        <div className='description bolded'>{t('research.page.topic-1.section-2.list-description')}</div>
                        <div className='infrastructure-section'>
                            {infrastructureItems.map((item) => (
                                <div className='infrastructure-item'>
                                    <div className='dot'></div>
                                    <span>{item.text}</span>
                                    <img src={item.image}></img>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div id="national-projects">
                    <ResearchPagePartProjects />
                </div>


                <div className='separator'></div>

                <DropdownSection title={t('research,page.topic-5.title')}>
                    <div id="resources-panel" className='resources-panel-container'>
                        <div className='panel'>
                            <div className='title'>{t('research.page.topic-5.section-1.title')}</div>
                            <div className='separator'></div>
                            <div className='section'>
                                {publications.map((item) => (
                                    <DownloadItem
                                        text={item.name} fileId={item.id} fileName={item.name} />
                                ))}
                            </div>
                        </div>
                        <div className='panel'>
                            <div className='title'>{t('research.page.topic-5.section-2.title')}</div>
                            <div className='separator'></div>
                            <div className='section'>
                                {activityReports.map((item) => (
                                    <DownloadItem
                                        text={item.name} fileId={item.id} fileName={item.name} />
                                ))}
                            </div>
                        </div>
                        <div className='panel'>
                            <div className='title'>{t('research.page.topic-5.section-3.title')}</div>
                            <div className='separator'></div>
                            <div className='section'>
                                {regstry.map((item) => (
                                    <DownloadItem
                                        text={item.name} fileId={item.id} fileName={item.name} />
                                ))}
                            </div>
                        </div>
                    </div>
                </DropdownSection>

                <div className='separator'></div>
                <div id="performances" className='reports-and-results-container'>
                    <DropdownSection title={t('research.page.topic-3.section-2.title')}>
                        <div className='flex-column gap-20'>
                        <div className='reports-and-results'>
                            {/* <div className='subtopic'>
                            <div className='subtitle'>{t('research.page.topic-3.section-1.title')}</div>
                            <img src={assortmentImage}></img>
                        </div> */}
                            <div className='subtopic'>
                                <div className='category'>
                                    <div className='title'>{t('research.page.topic-3.section-2.category-1.title')}</div>
                                    <div className='results'>
                                        {apricot.map((item) => (
                                            <DownloadItem
                                                text={item.name} fileId={item.id} fileName={item.name} />
                                        ))}
                                    </div>
                                </div>
                                <div className='category'>
                                    <div className='title'>{t('research.page.topic-3.section-2.category-2.title')}</div>
                                    <div className='results'>
                                        {peach.map((item) => (
                                            <DownloadItem
                                                text={item.name} fileId={item.id} fileName={item.name} />
                                        ))}
                                    </div>
                                </div>
                                <div className='category'>
                                    <div className='title'>{t('research.page.topic-3.section-2.category-3.title')}</div>
                                    <div className='results'>
                                        {nectarine.map((item) => (
                                            <DownloadItem text={item.label} />
                                        ))}
                                    </div>
                                </div>
                                <div className='category'>
                                    <div className='title'>{t('research.page.topic-3.section-2.category-4.title')}</div>
                                    <div className='results'>
                                        <DownloadItem text={'Soiuri din catalogul ISTS'} fileId='cffaacce-630b-4602-9855-a020ac24aeb2' fileName='Soiuri din catalogul ISTS' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='performances-container'>
                            <div className='performances-title'>{t('research.page.topic-4.title')}</div>
                            <div className='sections-container'>
                                <div className='performances-section'>
                                    {Array.from({ length: 15 }).map((_, index) => (
                                        <div className='item' key={index}>
                                            <div className='dot'></div> {t(`research.page.topic-4.section-1.item-${index + 1}`)}
                                        </div>
                                    ))}
                                </div>
                                <div className='performances-section section-2'>
                                    {Array.from({ length: 7 }).map((_, index) => (
                                        <div className='item' key={index}>
                                            <div className='dot'></div> <p><Trans i18nKey={`research.page.topic-4.section-2.item-${index + 1}`} components={{ strong: <strong /> }} /></p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        </div>
                    </DropdownSection>

                </div>

            </div>
        </div>
    )
}

export default ResearchPage;